import React, { useState, useEffect } from "react";
import Button from "../components/button";
import { callAPI } from "../networks/callApi";
import { BASE_URL } from "../commonservice/commonservice";
import { ENDPOINTS_API } from "../networks/apiConstants";
import InputComponent from "../components/inputComponent";
import SelectComponent from "../components/selectComponet"; // Ensure it's imported
import { MenuItem } from "@mui/material";
import { handleGetMatchRuleById } from "../networks/adminApi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditMatchRule = () => {
  const [gameType, setGameType] = useState(""); // State for game type
  const [gameName, setGameName] = useState(""); // State for game name
  const [title, setTitle] = useState(""); // State for match title
  const [addRule, setAddRule] = useState(""); // State for new rule input
  const [rules, setRules] = useState([]); // State for added rules
  const [ruleNo, setRuleNo] = useState("");
  const [updateRules, setUpdateRules] = useState(false);
  const [editIndex, setEditIndex] = useState(null); // State to track the index of the rule being edited
  const navigate = useNavigate();

  const { id } = useParams();
  const addruleList = ["ludo", "Games"];

  // API call for editing match rule
  const handleEditMatchRule = () => {
    if (!gameType || !gameName || !title || rules.length === 0) {
      toast.error("All fields are required");
      return;
    }
    callAPI(
      BASE_URL + ENDPOINTS_API.EDIT_MATCH_RULE + `/${id}`,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        gameType,
        name: gameName,
        title,
        rules,
      })
    )
      .then((res) => {
        navigate("/matchRules");
        toast.success("MatchRule Edited Successfully");
      })
      .catch((err) => {});
  };

  const getMatchRule = async () => {
    const res = await handleGetMatchRuleById({ id });

    if (res?.data) {
      setGameName(res?.data?.name);
      setRules(res?.data?.rules);
      setGameType(res?.data?.gameType);
      setTitle(res.data?.title);
    }
  };

  useEffect(() => {
    getMatchRule();
  }, []);

  const handleUpdateRule = () => {
    if (editIndex !== null) {
      const updatedRules = rules.map((item, index) => {
        return editIndex === index ? addRule : item;
      });
      setRules(updatedRules);
      setEditIndex(null); // Clear the edit index after updating
    }
    setAddRule(""); // Clear the input field
  };

  const handleRuleChange = (e) => {
    const value = e.target.value;
    setAddRule(value);
  };

  const addOrUpdateRule = () => {
    if (editIndex !== null) {
      // Update existing rule
      handleUpdateRule();
    } else if (ruleNo) {
      // Add new rule
      const updatedRules = rules.map((item, index) => {
        return ruleNo - 1 === index ? addRule : item;
      });
      setRules(updatedRules);
      setAddRule("");
    } else {
      // Add new rule
      setRules([...rules, addRule]);
      setAddRule("");
    }
  };

  const handleEditButtonClick = (index) => {
    setEditIndex(index); // Set the index of the rule to be edited
    setAddRule(rules[index]); // Populate the input field with the rule to be edited
  };

  return (
    <div className="mx-4 mt-2 text-white">
      {/* Game Type Selector */}
      <SelectComponent
        value={gameType}
        onSelect={setGameType} // Use onChange instead of onSelect
        label="Game Type"
        width={"50%"}
        height={"50px"}
        data={addruleList}
      />

      <InputComponent
        lable={"Game Name"}
        placeholder={"Please enter Game Name"}
        value={gameName}
        onChange={setGameName}
      />
      <InputComponent
        lable={"Match Title"}
        placeholder={"Please enter match title"}
        value={title}
        onChange={setTitle}
      />
      {/* <InputComponent
        lable={"Add Rule No"}
        placeholder={"Please enter No"}
        value={ruleNo}
        onChange={setRuleNo}
        type={"number"}
      /> */}
      <input
        lable={"Add Rule"}
        style={{
          background: "#222d64",
          marginTop: "1rem",
          borderRadius: "5px",
          width: "96vw",
          height: "4.5vh",
          border: "1px solid",
        }}
        className="text-black"
        placeholder={"Please enter rule"}
        value={addRule}
        onChange={(e) => handleRuleChange(e)}
      />

      <div className="mt-4 flex justify-between">
        <Button lable={"Add/Update Rule"} onClick={addOrUpdateRule} />
        <Button lable={"Edit Rules"} onClick={handleEditMatchRule} />
      </div>

      {rules.length > 0 && (
        <>
          <div className="mt-4 text-white">{gameName}</div>
          {rules.map((item, index) => (
            <div
              key={index}
              className="text-white flex justify-between items-center"
            >
              <span>
                {index + 1}. {item}
              </span>
              <Button
                lable={"Edit"}
                onClick={() => handleEditButtonClick(index)}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default EditMatchRule;
