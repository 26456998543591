/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {
  handleEditPlayerData,
  handleGameEventById,
  handleGetParticipants,
  handleUpdateWinner,
  handleEditAllWinnerData,
} from "../../networks/adminApi";
import Table from "../../components/table";
import InputComponent from "../../components/inputComponent";
import SelectComponet from "../../components/selectComponet";
import Button from "../../components/button";
import { toast } from "react-toastify";
import { FaEdit, FaEye } from "react-icons/fa";
import { SocketProvider } from "../../socket/SocketsContextProvider";
import Pagination from "../../components/pagination";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrizePoolData,
  setStoreInputState,
} from "../../store/reducer/usersReducer";
const UserDetails = ({ id }) => {
  return <Link to={`/getParticipantDetials/${id}`}>user Details</Link>;
};

const NameComponent = ({ data }) => {
  return (
    <div>
      <div>
        UserName:{" "}
        {data?.username?.length < 25
          ? data?.username
          : data?.username.slice(0, 25) + "..."}
      </div>
      <div>
        UserId:{" "}
        {data?.userId?.length > 25
          ? data.userId.slice(0, 25) + "..."
          : data.userId}
      </div>
    </div>
  );
};

const TotalKillComponent = ({
  id,
  value,
  onChange,
  perKill,
  handleBlurKillUpdate,
  handleFocusKillUpdate,
}) => {
  return (
    <div>
      <InputComponent
        onBlur={handleBlurKillUpdate}
        onFocus={handleFocusKillUpdate}
        value={value}
        onChange={(e) => onChange(id, "totalKill", e)}
        type={"number"}
      />
      {/* <div>x{perKill}</div> */}
    </div>
  );
};

const PoolPrizeComponent = ({ id, value, onChange, kills, perKillAmount }) => {
  return (
    <div>
      <InputComponent
        value={value}
        onChange={(e) => onChange(id, "poolPrize", e)}
        type={"number"}
      />
    </div>
  );
};

const WinningAmount = ({
  id,
  value,
  onChange,
  kills,
  perKillAmount,
  prizeMoneyAmount,
  position,
  poolPrize,
}) => {
  return (
    <div>
      <InputComponent
        value={
          parseInt(kills) * parseInt(perKillAmount) +
            parseInt(poolPrize || 0) || value
        }
        onChange={(e) => onChange(id, "winningAmount", e)}
        type={"number"}
        readOnly={true}
      />
    </div>
  );
};

const PositionComponent = ({ id, value, ranks, onSelect }) => {
  return (
    <div>
      <SelectComponet
        value={value}
        onSelect={(e) => onSelect(id, "position", e)}
        data={ranks}
        width={"w-[120px]"}
      />
    </div>
  );
};

const GetDescription = () => {
  const [eventData, setEventData] = useState({});
  const [participantsData, setParticipantsData] = useState();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [ranks, setRanks] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [perKillAmount, setPerKillAmount] = useState("");
  const [prizeMoneyAmount, setPrizeMoneyAmount] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Added search term state
  const { socketRef } = useContext(SocketProvider);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [gameEventId, setGameEventId] = useState("");
  const [dataSaved, setDataSaved] = useState(false);
  const [totalParticipants, setTotalParticipants] = useState("");
  const [disableSendPrize, setDisableSendPrize] = useState(false);

  //edited
  const [editPoolPrize, setEditPoolPrize] = useState("");
  const [editWinningAmount, setEditWinningAmount] = useState("");
  const [editPosition, setEditPosition] = useState("");
  const [editKills, setEditKills] = useState("");
  const [showRankList, setShowRankList] = useState(false);

  const dispatch = useDispatch();

  const { prizePoolData, storeInputState } = useSelector(
    (state) => state.usersList
  );

  useEffect(() => {
    // const inputsLength = Object.keys(inputs).length;
    const newRanks = generateRanks(totalParticipants);
    setRanks(newRanks);
  }, [inputs]);

  const generateRanks = (length) => {
    const rankTitles = ["Winner", "2nd Runner-up", "3rd Runner-up"];
    const newRanks = [];

    for (let i = 0; i < length; i++) {
      if (i < rankTitles.length) {
        newRanks.push(rankTitles[i]);
      } else {
        newRanks.push(`${i + 1}th Rank`);
      }
    }

    return newRanks;
  };

  const handleInputChange = (_id, item, value, items) => {
    if (value < 0) {
      toast.error("value Cannot be negative");
      return;
    }
    setInputs((prev) => ({
      ...prev,
      [_id]: {
        ...(prev[_id] || {}),
        [item]: value || "",
      },
    }));
    // const handleAdminPrize = () => {
    //   callAPI(
    //     BASE_URL + ENDPOINTS_API.ADMIN_PRIZE,
    //     "POST",
    //     {
    //       "Content-type": "application/json",
    //     },
    //     JSON.stringify({
    //       userId: items._id,
    //       kills: value,
    //       // positions: inputs[items._id]["position"],
    //       gameEventId: items?.gameEventId,
    //     })
    //   )
    //     .then((res) => {
    //
    //       setTotalPrice(res.totalPrize);
    //     })
    //     .catch((err) => {
    //
    //     });
    // };
    // handleAdminPrize();
  };

  const handleOptionChange = (_id, item, value) => {
    setInputs((prev) => ({
      ...prev,
      [_id]: {
        ...(prev[_id] || {}),
        [item]: value || "",
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGameEventById({ id });
      if (res?.data) {
        setPrizeMoneyAmount(res?.data?.prize);
        setPerKillAmount(res?.data?.perKill);
        setEventData(res?.data);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const res2 = await handleGetParticipants({ id });

      if (res2?.data) {
        // Initialize state for each participant's input fields
        const initialInputs =
          res2.data.participants.reduce((acc, participant) => {
            acc[participant._id] = {
              totalKill: participant.kills || "",
              poolPrize: participant.poolPrize ? participant.poolPrize : 0,
              position: participant.position || "",
              winningAmount: participant.winningAmount || "",
              id: participant.userId,
            };
            return acc;
          }, {}) || {};
        setTotalParticipants(res2?.data?.totalPartcipants);
        setInputs(initialInputs);
        setParticipantsData(res2?.data?.participants);
        setTotalPages(res2?.data.totalPages);
      }
    };
    fetchData();
  }, [id]);

  const filteredPrticipants = participantsData?.filter((user) => {
    return (
      String(user?.username)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      String(user?.joinedByUserId?.mobile)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      String(user?.userId)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(user?.joinedByUserId?.email)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    // kills * perKillAmount + parseInt(poolPrize)
    setEditWinningAmount(editKills * perKillAmount + parseInt(editPoolPrize));
  }, [editKills, editPoolPrize]);

  const columns = [
    // { Header: "Game Name", accessor: "gameName" },
    { Header: "Title", accessor: "title" },
    { Header: "Event Type", accessor: "eventType" },
    { Header: "Entry Fee", accessor: "entryFee" },
    // { Header: "Image Preview", accessor: "imgPreview" },
    { Header: "Date", accessor: "date" },
    { Header: "Map", accessor: "map" },
    { Header: "Per Kill", accessor: "perKill" },
    { Header: "Prize", accessor: "prize" },
    { Header: "Version", accessor: "version" },
  ];

  const eventDescription = [];

  eventDescription.push({
    id: eventData.id,
    title: eventData.title,
    eventType: eventData.eventType,
    entryFee: eventData.entryFee || "free",

    // imgPreview: (
    //   <div className="flex justify-center">
    //     <img src={eventData.image} className="h-10 w-10" />
    //   </div>
    // ),
    date: eventData.date,
    map: eventData.map,
    perKill: eventData.perKill,
    prize: eventData.prize,
    version: eventData.version,
  });

  const gameEventHeading = {
    heading: "Game Details",
  };

  const ParticipantHeader = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Players", accessor: "name" },
    { Header: "Joined By", accessor: "joinedBy" },
    { Header: "Mobile No.", accessor: "mobileno" },
    { Header: "eamil", accessor: "email" },
    { Header: "Total Kill", accessor: "totalKill" },
    { Header: "Pool Prize", accessor: "poolPrize" },
    { Header: "Position", accessor: "position" },
    { Header: "Winning Amount", accessor: "winningAmount" },
    // { Header: "Action", accessor: "action" },
  ];

  const handleBlurKillUpdate = ({ itemId }) => {
    dispatch(setStoreInputState(inputs));
  };

  const handleFocusKillUpdate = () => {};

  const participantList = [];
  filteredPrticipants &&
    filteredPrticipants?.forEach((items) =>
      participantList.push({
        id: items._id,
        s_no: items.s_no,
        name: <NameComponent data={items} />,
        joinedBy: items?.joinedByUserId?.name,
        mobileno: items?.joinedByUserId?.mobile,
        email: items?.joinedByUserId?.email,
        totalKill: (
          <TotalKillComponent
            // handleBlurKillUpdate={() =>
            //   handleBlurKillUpdate({ itemId: items._id })
            // }
            // handleFocusKillUpdate={handleFocusKillUpdate}
            id={items._id}
            value={
              inputs[items._id]?.totalKill
              // ??
              // storeInputState[items._id]?.totalKill ??
              // prizePoolData[items._id]?.totalKill
            }
            onChange={(e, item, value) =>
              handleInputChange(e, item, value, items)
            }
            perKill={eventData.perKill}
          />
        ),
        poolPrize: (
          <PoolPrizeComponent
            id={items._id}
            value={
              inputs[items._id]?.poolPrize
              //  ??
              // prizePoolData[items._id]?.poolPrize
            }
            onChange={(e, item, value) => handleInputChange(e, item, value)}
            kills={inputs[items._id]?.totalKill || ""}
            perKillAmount={perKillAmount}
          />
        ),
        position: (
          <PositionComponent
            id={items._id}
            value={
              inputs[items._id]?.position
              // ?? prizePoolData[items._id]?.position
            }
            ranks={ranks}
            onSelect={handleOptionChange}
          />
        ),
        winningAmount: (
          <WinningAmount
            id={items._id}
            value={
              inputs[items._id]?.winningAmount
              //  ??
              // prizePoolData[items._id]?.winningAmount
            }
            onChange={(e, item, value) => handleInputChange(e, item, value)}
            kills={
              inputs[items._id]?.totalKill || ""
              // prizePoolData[items._id]?.totalKill ||
            }
            perKillAmount={perKillAmount}
            prizeMoneyAmount={prizeMoneyAmount}
            position={inputs[items._id]?.position || ""}
            poolPrize={
              inputs[items._id]?.poolPrize ||
              // prizePoolData[items._id]?.poolPrize ||
              ""
            }
          />
        ),
        action: (
          <div className="item-center  justify-center place-self-center">
            <button
              onClick={() => [
                // setOpenModal(true),
                // setEditKills(inputs[items._id]?.totalKill),
                // setEditPoolPrize(inputs[items._id]?.poolPrize),
                // setEditPosition(inputs[items._id]?.position),
                // setEditWinningAmount(inputs[items._id]?.winningAmount),
                setSelectedUserId(items.userId),
                setGameEventId(items._id),
                handleConfirm(),
              ]}
              className="flex justify-center item-center"
            >
              {/* <FaEdit /> */}
              Update Data
            </button>
          </div>
        ),

        // viewDocument: (
        //   <div className="flex justify-center cursor-pointer">
        //     <FaEye className="text-blue-500 hover:text-blue-700" />
        //   </div>
        // ),
        // userDetails: <UserDetails id={items._id} />,
      })
    );

  const participantsHeading = {
    heading: "All Participants",
  };

  const validateInputs = () => {
    for (let key in inputs) {
      const { totalKill, poolPrize, winningAmount } = inputs[key];
      if (
        parseInt(totalKill) <= 0 ||
        parseInt(poolPrize) <= 0 ||
        parseInt(winningAmount) <= 0
      ) {
        return false;
      }
    }
    return true;
  };

  const handleEditWinners = async () => {
    const users = Object.entries(inputs).map(([key, value]) => ({
      userId: key,
      kills: parseInt(value.totalKill),
      position: value.position,
      id: value.id,
      poolPrize: value.poolPrize,
      winningAmount:
        parseInt(value.totalKill * perKillAmount) + parseInt(value.poolPrize),
    }));

    // if (currentPage === totalPages && dataSaved) {
    if (participantsData.length > 0) {
      const res = await handleEditAllWinnerData({
        gameEventId: eventData._id,
        users,
      });

      if (res?.success) {
        // setCurrentPage(1);
        // if (currentPage === 1) {
        handleGetParticipants({ id });
        // }

        if (socketRef.current) {
          const userIds = users.map((user) => user.userId);
          socketRef.current.emit("update-balance", { id: userIds });
        }
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } else {
      toast.warn("Please wait for players to join the match");
    }
    // } else {
    //   toast.warn("Please go to last page and save the data");
    // }
  };

  const handleSendPrize = async () => {
    const users = Object.entries(inputs).map(([key, value]) => ({
      userId: key,
      kills: parseInt(value.totalKill),
      position: value.position,
      id: value.id,
      poolPrize: value.poolPrize,
      winningAmount:
        parseInt(value.totalKill) * parseInt(perKillAmount) +
        parseInt(value.poolPrize || 0),
    }));
    // if (currentPage === totalPages && dataSaved) {
    if (participantsData.length > 0) {
      setDisableSendPrize(true);
      const res = await handleUpdateWinner({
        gameEventId: eventData._id,
        users,
      });

      if (res?.success) {
        setDisableSendPrize(false);
        if (socketRef.current) {
          const userIds = users.map((user) => user.userId);
          socketRef.current.emit("update-balance", { id: userIds });
        }
        toast.success(res?.message);
      } else {
        setDisableSendPrize(false);
        toast.error(res?.message);
      }
    } else {
      toast.warn("Please wait for players to join the match");
    }
    // } else {
    //   toast.warn("Please go to last page and save the data");
    // }
  };

  const handleStateUpdate = () => {
    dispatch(setPrizePoolData(inputs));
  };

  // useEffect(() => {
  //   dispatch(setStoreInputState(inputs));
  // }, [inputs]);

  const handleConfirm = async () => {
    // kills: parseInt(value.totalKill),
    //   position: value.position,
    //   id: value.id,
    //   poolPrize: value.poolPrize,
    //   winningAmount:

    const data = {
      gameEventId: eventData._id,
      userId: selectedUserId, // selectedUserId,
      kills: inputs[gameEventId].totalKill, //editKills,
      winningAmount:
        parseInt(inputs[gameEventId].totalKill) * perKillAmount +
        inputs[gameEventId].poolPrize, // editWinningAmount,
      position: inputs[gameEventId].position, //editPosition,
      poolPrize: inputs[gameEventId].poolPrize, //parseInt(editPoolPrize),}
    };

    if (selectedUserId !== "" && gameEventId !== "") {
      const res = await handleEditPlayerData({
        data,
      });

      if (res?.success) {
        toast.success("Player winning updated successfylly");
        // setOpenModal(false);
        handleGetParticipants({ id });
      }
    }
  };

  return (
    <div className="mx-4">
      <Table
        columns={columns}
        data={eventDescription}
        titleData={gameEventHeading}
        headingSize={"text-[1rem]"}
      />

      <div>
        {/* <Button lable={"Send Prize"} onClick={() => handleSendPrize()} /> */}
        <div className="search-bar" style={{ marginTop: "1rem" }}>
          <input
            type="text"
            placeholder="Search here....."
            value={searchTerm}
            onChange={handleSearch}
            style={{
              padding: "5px",
              width: "100%",
              maxWidth: "300px",
              borderRadius: "4px",
              border: "1px solid",
              marginLeft: "5px",
            }}
          />
        </div>
        <Table
          columns={ParticipantHeader}
          data={participantList}
          titleData={participantsHeading}
          headingSize={"text-[1rem]"}
        />
        {/* <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          stateupdate={true}
          handleStateUpdate={handleStateUpdate}
          setDataSaved={setDataSaved}
        /> */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Button
            lable={disableSendPrize ? "sending Prize" : "Send Prize"}
            disabled={disableSendPrize}
            onClick={() => handleSendPrize()}
          />
          {/* <Button
            lable={"Update Prize"}
            ml={"ml-[20px]"}
            onClick={() => handleEditWinners()}
          /> */}
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="block-unblock-modal"
        aria-describedby="block-unblock-description"
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            margin: "10% auto",
            width: "300px",
            borderRadius: "8px",
          }}
        >
          <h2 id="block-unblock-modal">Edit Prize Pool</h2>
          <p id="block-unblock-description">
            Are you sure you want to edit this user Prize pool
          </p>
          <div>
            <input
              placeholder="Kills"
              className="border-[1px] rounded-[5px] pl-[4px] mt-[5px]"
              value={editKills}
              onChange={(e) => setEditKills(e.target.value)}
            />
            <input
              placeholder="Pool Prize"
              className="border-[1px] rounded-[5px] pl-[4px] mt-[5px]"
              value={editPoolPrize}
              onChange={(e) => setEditPoolPrize(e.target.value)}
            />
            <div className="relative">
              <button onClick={() => setShowRankList(!showRankList)}>
                <input
                  placeholder="position"
                  className="border-[1px] rounded-[5px] pl-[4px] mt-[5px]"
                  value={editPosition}
                  // onChange={(e) => setEditPosition(e.target.value)}
                />
              </button>
              {showRankList && (
                <div className="max-h-[100px] overflow-scroll mt-[5px]">
                  {ranks.map((item) => {
                    return (
                      <div>
                        <button onClick={() => setEditPosition(item)}>
                          {item}
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <input
              placeholder="winningAmount"
              className="border-[1px] rounded-[5px] pl-[4px] mt-[5px]"
              value={editWinningAmount}
              onChange={(e) => setEditWinningAmount(e.target.value)}
              readOnly={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              lable={"Cancel"}
              variant="contained"
              onClick={() => setOpenModal(false)}
            />

            <Button
              lable={"edit"}
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GetDescription;
