import React, { useEffect, useState } from "react";
import Table from "../../components/table";
import {
  handleBannerDeleteById,
  handleGetAllBanner,
} from "../../networks/adminApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "../../components/pagination";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";

const BannerAction = ({ id, setBannerDeleted }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handelDeleteBanner = async () => {
    const res = await handleBannerDeleteById({ id });

    if (res.success) {
      toast.success("Banner Deleted successfully");
      setBannerDeleted(true);
      handleClose();
    }
  };

  return (
    <div className="flex flex-row justify-center">
      <Link to={`/editBanner/${id}`} className="mr-2">
        <FaEdit />
      </Link>
      <button onClick={handleClickOpen}>
        <MdDelete />
      </button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this banner?</p>
        </DialogContent>
        <DialogActions>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handelDeleteBanner}
            color="error"
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AllBanner = () => {
  const [allBannerList, setAllBannerList] = useState([]);
  const [deletedBanner, setBannerDeleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGetAllBanner(currentPage);

      if (res?.data) {
        setAllBannerList(res?.data);
        setBannerDeleted(false);
        // setTotalPages(res.data.totalPages);
      }
    };
    fetchData();
  }, [deletedBanner, currentPage]);

  const columns = [
    { Header: "S.No", accessor: "s_no" },

    { Header: "URL", accessor: "url" },
    { Header: "Type", accessor: "type" },
    { Header: "Image Preview", accessor: "imgPreview" },
    { Header: "Action", accessor: "action" },
  ];

  let bannerData = [];

  allBannerList &&
    allBannerList.forEach((item) => {
      bannerData.push({
        s_no: item.s_no,

        id: item.id,
        url: item.url,
        imgPreview: (
          <div className="flex justify-center">
            <img
              src={item.image}
              className="h-10 w-10 object-contain"
              alt="img"
            />
          </div>
        ),
        type: item.type,

        action: (
          <BannerAction id={item.id} setBannerDeleted={setBannerDeleted} />
        ),
      });
    });

  const bannerHeading = {
    heading: "All Banner",
  };

  return (
    <div>
      {bannerData.length > 0 ? (
        <Table columns={columns} data={bannerData} titleData={bannerHeading} />
      ) : (
        <div className="text-white flex justify-center mt-10">
          No Data Found...
        </div>
      )}
      {/* <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      /> */}
    </div>
  );
};

export default AllBanner;
