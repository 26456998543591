import { useEffect, useState } from "react";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";
import { toast } from "react-toastify";
import Table from "../../components/table";
import moment from "moment";

// URL validation regex function
const isValidURL = (url) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // Protocol (optional)
      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // Domain name
      "localhost|" + // localhost
      "\\d{1,3}(\\.\\d{1,3}){3})" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$", // Port and path (optional)
    "i"
  );
  return !!urlPattern.test(url);
};

const Index = () => {
  const [videoLink, setVideoLink] = useState("");
  const [linkvideo, setLinkVideo] = useState();

  const handleaddvideolink = () => {
    // Validate URL before making the API call
    if (!isValidURL(videoLink)) {
      toast.error("Please enter a valid video URL");
      return;
    }

    callAPI(
      BASE_URL + ENDPOINTS_API.VIDEO_LINK,
      "POST",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({ videoLink })
    )
      .then((res) => {
        toast.success("VideoLink Added Successfully");
        setVideoLink(""); // Clear the input after successful submission
        handleGetvideoList(); // Reload the video list
      })
      .catch((err) => {
        toast.error("Failed to add video link");
      });
  };

  const handleGetvideoList = () => {
    callAPI(BASE_URL + ENDPOINTS_API.VIDEO_LINK_LIST, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        setLinkVideo(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleGetvideoList();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Link", accessor: "link" },
    { Header: "Created At", accessor: "createdAt" },
  ];

  const AddVideolinklist = [];

  linkvideo &&
    linkvideo.forEach((item, index) => {
      AddVideolinklist.push({
        s_no: index + 1,
        link: item.videoLink,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY , LT"),
      });
    });

  const tableHeading = {
    heading: "Video Link List",
  };

  return (
    <div>
      <div className="mt-4">
        <label className="text-white ml-4">HOW TO PLAY</label>
        <input
          value={videoLink}
          onChange={(e) => setVideoLink(e.target.value)}
          className="flex flex-col mt-2 ml-4 w-[30vw] h-[5vh] rounded-[5px] p-2"
          type="text"
          placeholder="Enter Video Link"
        />
      </div>
      <div>
        <button
          onClick={handleaddvideolink}
          className="text-white ml-4 border rounded-[4px] mt-6 w-[8vw] h-[5vh]"
        >
          Submit
        </button>
      </div>
      <div className="mt-6">
        <Table
          columns={columns}
          data={AddVideolinklist}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>
    </div>
  );
};

export default Index;
