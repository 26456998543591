/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";
import Table from "../../components/table";
import Pagination from "../../components/pagination";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Box, Modal } from "@mui/material";
import { Button, Typography } from "@mui/material";

const index = ({ setFormData }) => {
  const [faq, setFaq] = useState([
    {
      question: "",
      answer: "",
    },
  ]);
  const [faqList, setFaqList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFaq, setEditFaq] = useState({ id: "", question: "", answer: "" });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [data, setData] = useState({
    question: "",
    answer: "",
  });
  const handleAddQuestion = () => {
    setFaq([...faq, { question: "", answer: "" }]);
  };

  const [questionError, setQuestionError] = useState("");
  const [answerError, setAnswerError] = useState("");

  const validation = () => {
    let valid = true;
    if (faq[0]?.question?.trim() === "") {
      valid = false;
      setQuestionError("Please enter question");
    } else {
      setQuestionError("");
    }

    if (faq[0]?.answer?.trim() === "") {
      valid = false;
      setAnswerError("Please enter answer");
    } else {
      setAnswerError("");
    }
    return valid;
  };

  const handleQuestionChange = (index, e) => {
    const updatedFaq = faq.map((item, i) =>
      i === index ? { ...item, [e.target.name]: e.target.value } : item
    );
    setFaq(updatedFaq);
  };

  const handleFaq = (status) => {
    if (validation()) {
      callAPI(
        BASE_URL + ENDPOINTS_API.FAQ,
        "POST",
        {
          "Content-type": "application/json",
        },
        JSON.stringify({
          question: faq[0].question,
          answer: faq[0].answer,
        })
      )
        .then((res) => {
          if (res?.data) {
            toast.success("Faq Added successfully");
            handleGetFaq();
            setFaq([
              {
                question: "",
                answer: "",
              },
            ]);
          }
        })
        .catch((err) => {});
    }
  };

  const handleGetFaq = () => {
    callAPI(BASE_URL + ENDPOINTS_API.GET_FAQ + `?page=${currentPage}`, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        if (res?.data) {
          setFaqList(res.data?.content);
          setTotalPages(res?.data?.totalPages);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleGetFaq();
  }, [currentPage]);

  const handleDeleteFaq = (id) => {
    callAPI(
      BASE_URL + ENDPOINTS_API.DELETE_FAQ,
      "DELETE",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({ deleteItemId })
    )
      .then((res) => {
        if (res?.data) {
          toast.success("Faq Deleted successfully");
          handleGetFaq();
        }
      })
      .catch((err) => {});
  };

  const handleUpdateFaq = () => {
    callAPI(
      BASE_URL + ENDPOINTS_API.UPDATE_FAQ,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        id: editFaq.id,
        question: editFaq.question,
        answer: editFaq.answer,
      })
    )
      .then((res) => {
        if (res?.data) {
          toast.success("FAQ Updated successfully");
          handleGetFaq();
          setIsEditModalOpen(false);
        }
      })
      .catch((err) => {});
  };

  const handleEditmodal = (value) => {
    setEditFaq(value);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (id) => {
    setDeleteItemId(id);
    setIsDeleteModalOpen(true);
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Id", accessor: "id" },
    { Header: "Question", accessor: "question" },
    { Header: "Answer", accessor: "answer" },
    { Header: "Action", accessor: "action" },
  ];

  const faqlistData = [];

  faqList &&
    faqList.forEach((item, index) => {
      faqlistData.push({
        s_no: index + 1,
        id: item.id,
        question: item.question,

        answer: item?.answer.slice(0, 20),
        action: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <FaEdit onClick={() => handleEditmodal(item)} />
            <MdDelete onClick={() => handleDeleteClick(item.id)} />
          </div>
        ),
      });
    });

  const tableHeading = {
    heading: "FAQ LIST",
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="space-y-2">
        <h2
          style={{
            color: "white",
            marginLeft: "1rem",
            marginTop: "1rem",
            fontSize: "20px",
          }}
        >
          FAQ Section
        </h2>
        {faq &&
          faq?.map((item, index) => (
            <div key={index} className="flex flex-col gap-5 ml-2">
              <input
                type="text"
                name="question"
                value={item.question}
                onChange={(e) => handleQuestionChange(index, e)}
                className="block w-1/2 p-2 border border-gray-300 rounded-md"
                placeholder="Question"
                required
              />
              {questionError && (
                <div className="text-white">* {questionError}</div>
              )}
              <input
                type="text"
                name="answer"
                value={item.answer}
                onChange={(e) => handleQuestionChange(index, e)}
                className="block w-1/2 p-2 border border-gray-300 rounded-md"
                placeholder="Answer"
                required
              />
              {answerError && <div className="text-white">* {answerError}</div>}
            </div>
          ))}

        <button
          style={{ marginTop: "1rem", marginLeft: "1rem" }}
          type="button"
          onClick={() => {
            handleFaq();
          }}
          className="px-3 py-1 bg-[#11aaf6] text-white rounded-md"
        >
          Submit FAQ
        </button>
      </div>

      <Table
        columns={columns}
        data={faqlistData}
        titleData={tableHeading}
        headingSize={"text-[1rem]"}
      />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete?
          </Typography>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button variant="contained" color="error" onClick={handleDeleteFaq}>
              Yes
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Edit FAQ Modal */}
      {/* Edit FAQ Modal */}
      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        aria-labelledby="edit-faq-modal"
        aria-describedby="modal-to-edit-faq"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="edit-faq-modal" className="text-xl mb-4">
            Edit FAQ
          </h2>
          <input
            type="text"
            name="question"
            value={editFaq.question}
            onChange={(e) =>
              setEditFaq({ ...editFaq, question: e.target.value })
            }
            className="block w-full p-2 border border-gray-300 rounded-md mb-3"
            placeholder="Edit Question"
            required
          />
          <input
            type="text"
            name="answer"
            value={editFaq.answer}
            onChange={(e) => setEditFaq({ ...editFaq, answer: e.target.value })}
            className="block w-full p-2 border border-gray-300 rounded-md mb-3"
            placeholder="Edit Answer"
            required
          />
          <button
            style={{
              background: "#2657cf",
              color: "white",
              width: "10vw",
              height: "5vh",
              cursor: "pointer",
              borderRadius: "5px",
            }}
            onClick={handleUpdateFaq}
          >
            Update FAQ
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default index;
