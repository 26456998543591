import React, { useEffect, useState } from "react";
import InputComponent from "../components/inputComponent";
import { handleGetUpiId, handlePostUpiId } from "../networks/adminApi";
import Button from "../components/button";

const UpiDetails = () => {
  const [upiId, setUpiId] = useState("");
  const [disabledButton, setDisableButton] = useState("");

  const fetchUpiId = async () => {
    const res = await handleGetUpiId();

    if (res?.data?.upiId) {
      setUpiId(res?.data?.upiId);
    }
  };

  useEffect(() => {
    fetchUpiId();
  }, []);

  // 9027112020@ptyes
  const updateUpiId = async () => {
    setDisableButton(true);
    const res = await handlePostUpiId({ upiId });
    if (res?.success) {
      setDisableButton(false);
      fetchUpiId();
    } else {
      setDisableButton(false);
    }
  };
  return (
    <div className="pl-[20px]">
      <InputComponent
        lable={"Upi Id"}
        placeholder={"please enter your upi id"}
        value={upiId}
        onChange={setUpiId}
        width={"w-[80%]"}
      />

      <Button
        lable={"update upi id"}
        width={"w-[200px]"}
        mt={"mt-[10px]"}
        rounded="rounded-[4px]"
        onClick={updateUpiId}
        disabled={disabledButton}
      />
    </div>
  );
};

export default UpiDetails;
