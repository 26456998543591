import React, { useState, useEffect, useContext } from "react";
import "./style.css"; // Make sure to add appropriate styles in this CSS file
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination";
import Table from "../../components/table";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";
import { SocketProvider } from "../../socket/SocketsContextProvider";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { Modal } from "@mui/material";
import Button from "../../components/button";
import LinkComponent from "../../components/linkComponent";

const Index = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [supportList, setSupportList] = useState([]);
  const [activeButton, setActiveButton] = useState("Pending");
  const [onSelect, setOnSelect] = useState();
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [act, setAct] = useState("");
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setopenRejectModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");

  const { socketRef } = useContext(SocketProvider);

  const handlechange = (e) => {
    setOnSelect(e.target.value);
  };

  const handleButtonClick = (status) => {
    setActiveButton(status);
  };

  const handleWithdrawls = ({ activeButton, currentPage }) => {
    callAPI(
      `${BASE_URL + ENDPOINTS_API.WITHDRAWLS}?page=${currentPage}`,
      "POST",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        status: activeButton,
      })
    )
      .then((res) => {
        if (res?.data) {
          setSupportList(res?.data?.transactions);
          setTotalPages(res?.data?.totalPages);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleWithdrawls({ activeButton, currentPage });
  }, [activeButton, currentPage]);

  const handleEditwithdrawl = (id, status) => {
    callAPI(
      BASE_URL + ENDPOINTS_API.WITHDRAWLS_EDIT,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        status: status,
        id,
      })
    )
      .then((res) => {
        toast.success(`Withdrawal request is ${status}`);
        if (status === "Rejected") {
          if (socketRef.current) {
            socketRef.current.emit("update-balance", { id });
          }
        }

        callAPI(
          BASE_URL + ENDPOINTS_API.WITHDRAWLS,
          "POST",
          {
            "Content-type": "application/json",
          },
          JSON.stringify({
            status: "Pending",
          })
        )
          .then((res) => {
            if (res?.data) {
              setSupportList(res?.data?.transactions);
              setTotalPages(res?.data?.totalPages);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Mobile Number", accessor: "mobilenumber" },
    { Header: "User Name", accessor: "name" },
    { Header: "Current Total Winnings", accessor: "winnings" },
    { Header: "Withdrawal Request Amount", accessor: "amount" },
    { Header: "Winning Matches", accessor: "winningMatches" },
    { Header: "Email", accessor: "email" },
    { Header: "Upi Id", accessor: "upiid" },

    { Header: "Time", accessor: "time" },
    { Header: "Status", accessor: "status" },
    { Header: "Action", accessor: "action" },
  ];

  const columns2 = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Mobile Number", accessor: "mobilenumber" },
    { Header: "User Name", accessor: "name" },
    { Header: "Total Winnings", accessor: "winnings" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Email", accessor: "email" },
    { Header: "Winning Matches", accessor: "winningMatches" },
    { Header: "Upi Id", accessor: "upiid" },
    { Header: "Time", accessor: "time" },
    { Header: "Status", accessor: "status" },
  ];

  const feedbackList = supportList?.map((item, index) => ({
    s_no: index + 1,
    mobilenumber: item.user?.mobile,
    name: item.user?.name,
    amount: item?.amount,
    email: item.user?.email,
    upiid: item?.upiID,
    winnings: item?.user?.winningAmount,
    winningMatches: (
      <LinkComponent
        width={"w-[200px]"}
        path={`/match-list/${item.user._id}`}
        lable={"Match Won"}
      />
    ),
    time: moment(item.createdAt).format("DD-MM-YYYY , LT"),
    status: (
      <div
        style={{
          color:
            item.status === "Rejected"
              ? "red"
              : item.status === "Accepted"
              ? "green"
              : "white",
        }}
      >
        {item.status}
      </div>
    ),
    action: (
      <>
        {activeButton === "Pending" && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            {/* <select
              value={onSelect}
              onChange={handlechange}
              style={{ borderRadius: "5px", color: "black" }}
            >
              <option value="select">Select</option>
              <option value="Accepted">Accept</option>
              <option value="Rejected">Reject</option>
            </select> */}
            <div className="flex gap-2">
              <button
                // onClick={() => handleEditwithdrawl(item._id, "Rejected")}

                onClick={() => [
                  setopenRejectModal(true),
                  setSelectedItemId(item?._id),
                ]}
                // style={{ border: "1px solid", borderRadius: "5px" }}

                className="underline active:border active:border-red-400 "
              >
                reject
              </button>

              <button
                // onClick={() => handleEditwithdrawl(item._id, "Accepted")}

                onClick={() => [
                  setOpenAcceptModal(true),
                  setSelectedItemId(item?._id),
                ]}
                // style={{ border: "1px solid", borderRadius: "5px" }}
                className="underline"
              >
                accept
              </button>
            </div>
            {/* <button
              onClick={() => handleEditwithdrawl(item._id)}
              style={{ border: "1px solid", borderRadius: "5px" }}
            >
              Submit
            </button> */}
          </div>
        )}
      </>
    ),
  }));

  const tableHeading = {
    heading: "Withdrawl List",
  };

  return (
    <div>
      {/* Withdrawal list buttons */}
      <div className="withdrawal-list-btn-container">
        <button
          className={`withdrawal-list-btn px-2 ${
            activeButton === "Accepted" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Accepted")}
        >
          Accepted
        </button>

        <button
          className={`withdrawal-list-btn px-2 ${
            activeButton === "Rejected" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Rejected")}
        >
          Rejected
        </button>

        <button
          className={`withdrawal-list-btn px-2 ${
            activeButton === "Pending" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Pending")}
        >
          Pending
        </button>
      </div>

      <div className="commission-slab-container">
        <Table
          columns={activeButton === "Pending" ? columns : columns2}
          data={feedbackList}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
      <Modal open={openAcceptModal} onClose={() => setOpenAcceptModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",

            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "5px",
            outline: "none",
          }}
        >
          <div className="px-[20px] py-[10px]">
            <div className="text-center">
              Are you sure you want to Accept this withdrawal?
            </div>
            <div className="flex flex-row justify-between mt-[20px]">
              <Button
                lable={"Cancel"}
                onClick={() => setOpenAcceptModal(false)}
              />
              <Button
                lable={"Accept"}
                onClick={() => [
                  handleEditwithdrawl(selectedItemId, "Accepted"),
                  setOpenAcceptModal(false),
                ]}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={openRejectModal} onClose={() => setopenRejectModal(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "5px",
            outline: "none",
          }}
        >
          <div className="px-[20px] py-[10px]">
            <div className="text-center">
              Are you sure you want to Reject this withdrawal?
            </div>
            <div className="flex flex-row justify-between mt-[20px]">
              <Button
                lable={"Cancel"}
                onClick={() => setopenRejectModal(false)}
              />
              <Button
                lable={"Reject"}
                onClick={() => [
                  handleEditwithdrawl(selectedItemId, "Rejected"),
                  setopenRejectModal(false),
                ]}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
