import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  handleDeleteEventById,
  handleGameById,
  handleEditGameEvent,
  handleCancelEventById,
} from "../../networks/adminApi";
import Table from "../../components/table";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import SelectComponet from "../../components/selectComponet";
import Button from "../../components/button";
import Pagination from "../../components/pagination";
import TopPlayer from "../topPlayer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { setPrizePoolData } from "../../store/reducer/usersReducer";
import { useDispatch } from "react-redux";

const DeleteEvent = ({
  id,
  setDeletedEvent,
  hasTransferredAmount,
  isCancelled,
}) => {
  const [open, setOpen] = useState(false);
  const [showAmountWarning, setShowAmountWarning] = useState(false);

  const handelCancelEvent = async () => {
    const res = await handleCancelEventById({ id });

    if (res?.success) {
      setShowAmountWarning(false);
      setOpen(false);
      toast.success("Event Cancel Successfully");
    } else {
      toast.error("Event Cancel failed");
    }
  };

  const handleClickOpen = () => {
    if (isCancelled) {
      setOpen(true);
    } else if (!hasTransferredAmount) {
      setShowAmountWarning(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowAmountWarning(false);
  };

  const handelDeleteEvent = async () => {
    const res = await handleDeleteEventById(id);

    if (res?.success) {
      setDeletedEvent(true);
      toast.success("Event deleted Successfully");
      handleClose();
    } else {
      toast.error("Event delete failed");
    }
  };

  return (
    <>
      {/* <button onClick={handleClickOpen}>
        <MdDelete />
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this event?</p>
        </DialogContent>
        <DialogActions>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={() => handelDeleteEvent()}
            color="error"
          >
            Delete
          </button>
        </DialogActions>
      </Dialog> */}
      <button onClick={handleClickOpen}>
        <MdDelete />
      </button>

      {/* Popup when the amount is not transferred */}
      <Dialog open={showAmountWarning} onClose={handleClose}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <p>
            You haven't transferred your amount yet. Do you still want to delete
            the event?
          </p>
        </DialogContent>
        <DialogActions>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={() => {
              setShowAmountWarning(false);
              setOpen(true);
              handelCancelEvent();
            }}
          >
            Proceed
          </button>
        </DialogActions>
      </Dialog>

      {/* Regular delete confirmation dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this event?</p>
        </DialogContent>
        <DialogActions>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handelDeleteEvent}
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CancelEvent = ({ id, setDeletedEvent, isCancelled }) => {
  const [Cancelled, setCanceled] = useState(false);
  const [open, setOpen] = useState(false);
  const handelCancelEvent = async () => {
    const res = await handleCancelEventById({ id });

    if (res?.success) {
      setDeletedEvent(true);
      setCanceled(true);
      setOpen(false);
      toast.success("Event Cancel Successfully");
    } else {
      toast.error("Event Cancel failed");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        style={{ color: isCancelled ? "red" : "white" }}
        onClick={() => setOpen(true)}
        disabled={isCancelled}
      >
        {isCancelled ? "Canceled" : "Cancel"}
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Cancel</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to Cancel this event?</p>
        </DialogContent>
        <DialogActions>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "15vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Don't cancel
          </button>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "15vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handelCancelEvent}
          >
            Cancel Event
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const GetDescription = ({ id }) => {
  return <Link to={`/getDescription/${id}`}>Check Participant</Link>;
};

const EditGameEventById = ({ id, name, isCancelled, roomId }) => {
  return (
    <div className="flex justify-center">
      <Link
        to={isCancelled ? "#" : `/editGameEvent/${name}/${id}`}
        style={{ pointerEvents: isCancelled ? "none" : "auto" }}
      >
        <FaEdit style={{ color: "white" }} />
      </Link>
    </div>
  );
};

const AddRoomId = ({ id, participants }) => {
  return (
    <>
      <Link to={`/addRoomId/${id}`} className="flex justify-center">
        <MdModeEditOutline />
      </Link>
    </>
  );
};

const EventTypeComponent = ({ type, item, isCancelled, roomId }) => {
  const [value, selectedValue] = useState(type);
  const [updatedEvent, setUpdatedEvent] = useState(item);

  useEffect(() => {
    selectedValue(type);
  }, [type]);

  const listData = ["ongoing", "completed"];

  useEffect(() => {
    setUpdatedEvent({ ...item, eventType: value, id: item._id });
  }, [value]);

  const handleEditEventType = async () => {
    const res = await handleEditGameEvent(updatedEvent);
    if (res?.success) {
      toast.success("Event Status updated successfully");
    } else {
      toast.error(res?.message);
    }
  };
  const isDisabled = isCancelled || !roomId;

  return (
    <div className="z-20 flex justify-between items-center">
      {/* {type} */}
      <SelectComponet
        value={value}
        onSelect={selectedValue}
        data={listData}
        width={"w-[100px]"}
        disabled={isDisabled} // Disable if cancelled or roomId/password missing
      />
      <Button
        lable={"submit"}
        mt={"mt-2"}
        ml={"ml-2"}
        onClick={() => handleEditEventType()}
        disabled={isDisabled} // Disable if cancelled or roomId/password missing
      />
    </div>
  );
};

const GetEventList = () => {
  const { id } = useParams();
  const [eventList, setEventList] = useState([]);
  const [deletedEvent, setDeletedEvent] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, SetLoading] = useState(true);
  const [filter, setFilter] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPrizePoolData({}));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      SetLoading(true);

      try {
        const res = await handleGameById({
          id,
          filter,
          searchTerm,
          currentPage,
        });

        if (res?.data) {
          setEventList(res?.data?.eventsWithDetails);
          setDeletedEvent(false);
          setTotalPages(res.data?.totalPages);
        }
      } finally {
        SetLoading(false);
      }
    };
    fetchData();
  }, [id, deletedEvent, currentPage, searchTerm, filter]);

  const filteredeventlist = eventList?.filter((item) => {
    const search = searchTerm.toLowerCase();
    return (
      String(item?.gameName)?.toLowerCase().includes(search) ||
      String(item?.title)?.toLowerCase().includes(search) ||
      String(item?.entryFee)?.toLowerCase().includes(search) ||
      String(item?.map)?.toLowerCase().includes(search) ||
      String(item?.perKill)?.toLowerCase().includes(search) ||
      String(item?.prize)?.toLowerCase().includes(search) ||
      String(item?.roomId)?.toLowerCase().includes(search)
    );
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },

    { Header: "Game Name", accessor: "gameName" },
    { Header: "Title", accessor: "title" },
    { Header: "Entry Fee", accessor: "entryFee" },
    { Header: "Add RoomId", accessor: "addRoomId" },
    { Header: "RoomId", accessor: "roomid" },
    { Header: "Cancel", accessor: "cancelEvent" },
    { Header: "Event Type", accessor: "eventType" },
    // { Header: "Image Preview", accessor: "imgPreview" },
    { Header: "Date", accessor: "date" },
    { Header: "Match Time", accessor: "matchtime" },
    // { Header: "Map", accessor: "map" },
    // { Header: "Per Kill", accessor: "perKill" },
    // { Header: "Prize", accessor: "prize" },
    // { Header: "Version", accessor: "version" },
    { Header: "Description", accessor: "description" },

    { Header: "Delete", accessor: "delete" },
    { Header: "Edit Event", accessor: "editEvent" },
  ];

  const eventData = [];
  eventList &&
    eventList.forEach((item, index) => {
      eventData.push({
        s_no: index + 1,

        id: item._id,
        gameName: item.gameName,
        title: item.title,
        eventType: (
          <EventTypeComponent
            type={item.eventType}
            item={item}
            roomId={item.roomId}
            isCancelled={item.isCancelled}
          />
        ),
        entryFee: item.entryFee || "free",

        // imgPreview: (
        //   <div className="flex justify-center">
        //     <img
        //       src={item.image}
        //       className="h-10 w-10 object-contain"
        //       alt="img"
        //     />
        //   </div>
        // ),
        date: item.date,
        matchtime: item.matchTime,
        // map: item.map,
        // perKill: item.perKill,
        // prize: item.prize,
        // version: item.version,
        editEvent: (
          <EditGameEventById
            id={item._id}
            name={item.gameName}
            isCancelled={item.isCancelled}
            roomId={item.roomId}
          />
        ),
        description: <GetDescription id={item._id} />,
        addRoomId: (
          <AddRoomId id={item._id} participants={item.participantCount} />
        ),
        roomid: item.roomId || "Not Updated",
        cancelEvent: (
          <CancelEvent
            id={item._id}
            setDeletedEvent={setDeletedEvent}
            isCancelled={item.isCancelled}
          />
        ),
        delete: (
          <DeleteEvent
            id={item._id}
            setDeletedEvent={setDeletedEvent}
            isAmountTransferred={item.isAmountTransferred}
            isCancelled={item.isCancelled}
          />
        ),
      });
    });

  const gameEventHeading = {
    heading: "All Events",
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="search-bar" style={{ marginTop: "1rem" }}>
          <input
            type="text"
            placeholder="Search here....."
            value={searchTerm}
            onChange={handleSearch}
            style={{
              padding: "5px",
              width: "100%",
              maxWidth: "300px",
              borderRadius: "4px",
              border: "1px solid",
              marginLeft: "5px",
            }}
          />
        </div>

        <div style={{ marginRight: "2rem", marginTop: "1rem" }}>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: "10vw", cursor: "pointer" }}
          >
            <option value="">Select Status</option>
            <option value="upcoming">Upcoming</option>
            <option value="result">Completed</option>
            <option value="ongoing">Ongoing</option>
          </select>
        </div>
      </div>
      <div className="mx-4">
        <Table
          columns={columns}
          data={eventData}
          titleData={gameEventHeading}
          headingSize={"text-[1rem]"}
        />

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </>
  );
};

export default GetEventList;
