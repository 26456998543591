import React, { useEffect, useState } from "react";
import Table from "./../components/table";
import { handlePaymentMode, handleSendPaymentMode } from "../networks/adminApi";
import { Modal } from "@mui/material";
import Button from "../components/button";
import { toast } from "react-toastify";

const PaymentSwitch = () => {
  const [totalPages, setTotalPages] = useState(1);
  const [paymentModeData, setPaymentModeData] = useState([]);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);

  const fetchPaymentMode = async () => {
    const res = await handlePaymentMode();
    if (res?.data) {
      setPaymentModeData([res?.data]);
    }
  };

  useEffect(() => {
    fetchPaymentMode();
  }, []);

  const handleConfirm = async () => {
    if (name !== "") {
      const res = await handleSendPaymentMode({ name });
      if (res?.data) {
        fetchPaymentMode();
        setName("");
      }
      setName("");
      setOpen(false);
    } else {
      toast.error("Please select a mode");
    }
  };

  const paymentHeader = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Name", accessor: "name" },
    { Header: "Action", accessor: "action" },
  ];

  const paymentMode = [];
  paymentModeData &&
    paymentModeData?.forEach((item, index) => {
      paymentMode.push({
        _id: item._id,
        s_no: index + 1,
        name: item?.name,
        action: (
          <button
            onClick={() => [setOpen(true)]}
            className="bg-blue-500 text-white px-2 py-1 rounded"
          >
            Change
          </button>
        ),
      });
    });

  const handleClose = () => {
    setOpen(false);
    setName("");
  };

  return (
    <div>
      <Table
        titleData={{ heading: "Paymnet Modes" }}
        columns={paymentHeader}
        data={paymentMode}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="block-unblock-modal"
        aria-describedby="block-unblock-description"
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            margin: "10% auto",
            width: "300px",
            borderRadius: "8px",
          }}
        >
          <p className="text-center">select the mode you want to apply</p>
          {paymentModeData[0]?.name === "UPI_GATEWAY" && (
            <div
              className={`cursor-pointer mt-[20px]`}
              style={{
                background: `${name === "ALL_UPI" ? "green" : "white"}`,
                color: `${name === "ALL_UPI" ? "white" : "black"}`,
                textAlign: "center",
              }}
              onClick={() => setName("ALL_UPI")}
            >
              ALL_UPI
            </div>
          )}
          {paymentModeData[0]?.name === "ALL_UPI" && (
            <div
              className={`cursor-pointer mt-[10px] `}
              style={{
                background: `${name === "UPI_GATEWAY" ? "green" : "white"}`,
                color: `${name === "UPI_GATEWAY" ? "white" : "black"}`,
                textAlign: "center",
              }}
              onClick={() => setName("UPI_GATEWAY")}
            >
              UPI_GATEWAY
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button onClick={handleClose}>Cancel</button>
            <button onClick={handleConfirm}>Yes</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentSwitch;
