import React, { useState, useEffect } from "react";
import InputComponent from "../../components/inputComponent";
import SelectComponet from "../../components/selectComponet";
import TextAreaComponent from "../../components/textAreaComponent";
import DateComponent from "../../components/dateComponent";
import InputImage from "../../components/inputImage";
import InputCheck from "../../components/inputCheck";
import Button from "../../components/button";
import { useParams } from "react-router-dom";
import {
  handleAddGameEvent,
  handleEditGameEvent,
  handleGameEventById,
} from "../../networks/adminApi";
import { toast } from "react-toastify";
import SelectTabComponent from "../../components/selecttabComponent";
import { handleGetRules } from "../../networks/adminApi";
import { useNavigate } from "react-router-dom";
const EditGameEvent = () => {
  const [selectedGame, setSelectedGame] = useState("");
  const [openGameList, setOpenGameList] = useState(false);
  const [matchTitle, setMatchTitle] = useState("");
  const [matchType, setMatchType] = useState("");
  const [openMatchTypeList, setOpenMatchTypeList] = useState("");
  const [selectedPoolPrize, setSelectedPoolPrize] = useState("");
  const [openPoolPrizeList, setOpenPoolPrizeList] = useState(false);
  const [poolPrizeDescription, setPoolPrizeDescription] = useState("");
  const [matchDate, setMatchDate] = useState("");
  const [matchTime, setMatchTime] = useState("");
  const [version, setVersion] = useState("");
  const [openVersionList, setOpenVersionList] = useState(false);
  const [coverImg, setCoverImg] = useState("");
  const [coverPreviewImg, setCoverPreviewImg] = useState("");
  const [platform, setPlatform] = useState("");
  const [openPlatFormList, setOpenPlatformList] = useState(false);
  const [entryType, setEntryType] = useState("");
  const [openEntryTypeList, setOpenEntryTypeList] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [pointsPerKill, setPointsPerKill] = useState("");
  const [totalPoolPrize, setTotalPoolPrize] = useState("");
  const [map, setMap] = useState("");
  const [sponsoredBy, setSponsoredBy] = useState("");
  const [spectralUrl, setSpectralUrl] = useState("");
  const [matchRule, setMatchRule] = useState("");
  const [openMatchRuleList, setOpenMatchRuleList] = useState("");
  const [roomId, setRoomId] = useState("");
  const [roomPassword, setRoomPassword] = useState("");
  const [roomSize, setRoomSize] = useState("");
  const [checkedInput, setCheckedInput] = useState(false);
  const [MatchRuleOption, setMatchRuleOption] = useState([]);
  const { id, name } = useParams();
  const [feeType, setFeeType] = useState("");

  const navigate = useNavigate();

  const convertToDateInputFormat = (dateString) => {
    if (!dateString) return "";

    // Check if dateString is already in YYYY-MM-DD format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }

    // Handle MM/DD/YYYY format
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      const [month, day, year] = dateString.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    // Handle DD/MM/YYYY format
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      const [day, month, year] = dateString.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    return "";
  };

  const fetchMatchRule = async () => {
    const res = await handleGetRules();

    if (res?.data?.rules) {
      setMatchRuleOption(res?.data?.rules);
    }
  };

  useEffect(() => {
    fetchMatchRule();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGameEventById({ id });

      if (res?.data) {
        setMatchDate(convertToDateInputFormat(res?.data?.date));
        if (res?.data?.entryFee > 0) {
          setEntryFee(res?.data?.entryFee);
          setFeeType("Paid");
        } else {
          setEntryFee(0);
        }
        setEntryType(res?.data?.eventType);
        setCoverImg(res?.data?.image);
        setMap(res?.data?.map);
        setPointsPerKill(res?.data?.perKill);
        setSelectedPoolPrize(res?.data?.prize);
        setMatchType(res?.data?.matchType);
        setVersion(res?.data?.version);
        setMatchTitle(res?.data?.title);
        setPoolPrizeDescription(res?.data?.discription);
        setMatchTime(res?.data?.matchTime);
        setRoomId(res?.data?.roomId);
        setRoomPassword(res?.data?.roomPassword);
        setRoomSize(res?.data?.roomSize);
        setMatchRule(res?.data?.matchRule);
        setSponsoredBy(res?.data?.sponsoredBy);
        // setTotalPoolPrize(res?.data?.totalPoolPrize);
        setSpectralUrl(res?.data?.spectateUrl);
        setPlatform(res?.data?.platform);
      }
    };
    fetchData();
  }, [id]);

  const gameList = ["Game 1", "Game 2", "Game 3"];
  const poolPrizeList = [
    "Pool Prize type 1",
    "Pool Prize type 2",
    "Pool Prize type 3",
  ];
  const matchTypeList = ["Solo", "Duo", "Squad"];
  const feeTypeList = ["Free", "Paid"];

  const versionData = ["TPP", "FPP"];

  const versionList = ["Version 1", "Version 2", "Version 3"];
  const platformList = ["Platform 1", "Platform 2", "platForm 3"];
  const entryTypeList = ["ongoing", "upcoming"];
  const matchRuleList = [
    "MatchRuleList 1",
    "Match Rule List 2",
    "Match Rule List 3",
  ];

  const formDataFunction = () => {
    const newData = new FormData();

    if (coverImg) {
      newData.append("image", coverImg);
    }

    // newData.append("game_id", id);
    newData.append("title", matchTitle);
    newData.append("prize", selectedPoolPrize);
    newData.append("date", matchDate.toString());
    newData.append("perKill", pointsPerKill);
    newData.append("entryFee", entryFee);

    newData.append("version", version);
    newData.append("map", map);
    newData.append("matchType", matchType);
    newData.append("discription", poolPrizeDescription);
    // newData.append("matchCode", roomId);
    newData.append("eventType", entryType);
    newData.append("id", id);
    newData.append("matchTime", matchTime);
    newData.append("platfrom", platform);
    // newData.append("totalPoolPrize", totalPoolPrize);
    newData.append("sponsoredBy", sponsoredBy);
    newData.append("matchRule", JSON.stringify(matchRule));
    newData.append("roomSize", roomSize);
    newData.append("spectateUrl", spectralUrl);
    newData.append("isPaid", feeType === "Paid" ? true : false);

    return newData;
  };

  const validtion = () => {
    let valid = true;

    if (coverImg === "") {
      valid = false;
    }
    if (id.trim() === "") {
      valid = false;
    }
    if (matchTitle.trim() === "") {
      valid = false;
    }
    if (selectedPoolPrize.toString().trim() === "") {
      valid = false;
    }
    if (matchDate.trim() === "") {
      valid = false;
    }
    if (pointsPerKill === "") {
      valid = false;
    }

    if (entryFee === "") {
      valid = false;
    }

    // if (matchType.trim() === "") {
    //   valid = false;
    // }
    if (version.trim() === "") {
      valid = false;
    }
    if (poolPrizeDescription.trim() === "") {
      valid = false;
    }
    if (roomId === "") {
      valid = false;
    }
    if (entryType.trim() === "") {
      valid = false;
    }

    return valid;
  };

  const handleEditEvent = async () => {
    if (entryFee < 0) {
      toast.error("Entry Fee cannot be negative");
      return;
    }
    if (selectedPoolPrize < 1) {
      toast.error("Prize cannot be negative or zero");
      return;
    }
    if (pointsPerKill < 0) {
      toast.error("Perkill cannot be negative");
      return;
    }
    if (roomSize < 1) {
      toast.error("RoomSize cannot be negative or zero");
      return;
    }
    const getData = formDataFunction();

    if (validtion()) {
      const res = await handleEditGameEvent(getData);
      if (res?.success) {
        toast.success("Event updated successfully");
        navigate(-1);
      }
    } else {
      toast.error("Please Fill all the fields");
    }
  };

  return (
    <div className="mx-4 pt-4 pb-4">
      <div className="text-white">Edit Match</div>
      <div className="text-grey text-[0.5rem]">
        Update the Field to edit Match
      </div>

      <div className="mt-4 text-[1.5rem] text-white">{name}</div>
      <div className="flex">
        <InputComponent
          lable={"Match Title*"}
          width={"w-[40%]"}
          // mlm={"ml-6"}
          value={matchTitle}
          onChange={setMatchTitle}
        />
      </div>
      <InputComponent
        lable={"Pool-Prize*"}
        width={"w-[30%]"}
        value={selectedPoolPrize}
        onChange={setSelectedPoolPrize}
      />
      <TextAreaComponent
        lable={"Prize Pool Description*"}
        placeholder={"Enter Prize-Pool description"}
        value={poolPrizeDescription}
        onChange={setPoolPrizeDescription}
      />
      <div className="flex flex-row">
        <DateComponent
          lable="Match Date*"
          width={"w-[33%]"}
          value={matchDate}
          onChange={setMatchDate}
          type={"date"}
        />
        <DateComponent
          lable="Match Time*"
          width={"w-[33%]"}
          value={matchTime}
          mlm={"ml-2"}
          onChange={setMatchTime}
          type={"time"}
        />
        <SelectComponet
          lable="Match Type*"
          width={"w-[33%]"}
          mlm={"ml-2"}
          value={matchType}
          onSelect={setMatchType}
          data={matchTypeList}
        />
        <SelectComponet
          lable="Version*"
          width={"w-[33%]"}
          mlm={"ml-2"}
          value={version}
          onSelect={setVersion}
          data={versionData}
        />
      </div>
      {/* <div className="flex flex-row">
        <InputImage
          lable={"Cover Image*"}
          width={"w-[66%]"}
          setFile={setCoverImg}
          setFilePreview={setCoverPreviewImg}
        />
        <SelectComponet
          lable={"Platform"}
          width={"w-[33%]"}
          mlm={"ml-2"}
          height={"h-[30px]"}
          value={platform}
          onSelect={setPlatform}
          data={platformList}
        />
      </div> */}
      <div>
        <SelectTabComponent
          lable={"Match Rules"}
          width={"w-[43%]"}
          mlm={""}
          height={"h-[30px]"}
          value={matchRule}
          onSelect={setMatchRule}
          data={MatchRuleOption}
        />
      </div>
      <div className="flex flex-row">
        <InputComponent
          lable={"Map*"}
          width={"w-[33%]"}
          value={map}
          onChange={setMap}
        />

        <div className="w-[25%] ml-2">
          <SelectComponet
            lable={"Select Fee Type*"}
            width={"w-[100%]"}
            height={"h-[25px]"}
            value={entryFee ? "paid" : "free"}
            onSelect={setFeeType}
            data={feeTypeList}
          />
        </div>
        {feeType === "Paid" && (
          <div className="w-[25%] ml-2">
            <InputComponent
              lable={"Entry Fee*"}
              width={"w-[100%]"}
              value={entryFee}
              onChange={setEntryFee}
              type={"number"}
            />
          </div>
        )}
        <InputComponent
          lable={"Points/Kills*"}
          width={"w-[33%]"}
          mlm={"ml-2"}
          value={pointsPerKill}
          onChange={setPointsPerKill}
        />
      </div>
      <div className="flex flex-row">
        <InputComponent
          lable={"Sponsored by"}
          width={"w-[100%]"}
          value={sponsoredBy}
          onChange={setSponsoredBy}
        />
      </div>
      <InputComponent
        lable={"Spectate URL"}
        value={spectralUrl}
        onChange={setSpectralUrl}
      />
      {/* <InputComponent
        lable={"Match Rules"}
        value={matchRule}
        onChange={setMatchRule}
      /> */}
      {/* <InputCheck
        lable={"Private Match"}
        value={checkedInput}
        onChange={setCheckedInput}
      /> */}
      <div className="mt-4 text-white">Room Details</div>
      <div className="flex flex-row">
        <InputComponent
          lable={"Room Size*"}
          width={"w-[100%]"}
          value={roomSize}
          onChange={setRoomSize}
        />
      </div>
      <div className="flex w-[100%] justify-end mt-2">
        <Button lable={"Edit Event"} onClick={handleEditEvent} />
      </div>
    </div>
  );
};

export default EditGameEvent;
