import React, { useContext, useEffect, useState } from "react";
import DashSection from "../components/dashBoard/dashSection";
import DashMain from "../components/dashBoard/dashMain";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { SocketProvider } from "../socket/SocketsContextProvider";

const Dashboard = () => {
  const [showSection, setShowSection] = useState(false);

  const { socketRef } = useContext(SocketProvider);
  useEffect(() => {
    if (socketRef?.current) {
      socketRef?.current.emit("ping");
      socketRef?.current.on("pong", (item) => {});
    }
  }, [socketRef?.current]);
  return (
    <div className="flex hide-scrollbar min-h-[100vh] bg-blue">
      <button
        onClick={() => setShowSection(!showSection)}
        className="absolute z-20 text-white sm:hidden"
      >
        {showSection ? <FaAngleLeft /> : <FaAngleRight />}
      </button>
      {<DashSection showSection={showSection} />}
      <DashMain />
    </div>
  );
};

export default Dashboard;
