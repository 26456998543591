import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
const secretIv = process.env.REACT_APP_ENCRYPTION_SECRET_IV;
const encryptionMethod = process.env.REACT_APP_ENCRYPTION_METHOD; // For reference; `crypto-js` uses AES directly.

// Generate a 32-byte encryption key from the secret key
const key = CryptoJS.SHA512(secretKey)
  .toString(CryptoJS.enc.Hex)
  .substring(0, 32);

// Generate a 16-byte IV from the secret IV
const encryptionIV = CryptoJS.SHA512(secretIv)
  .toString(CryptoJS.enc.Hex)
  .substring(0, 16);

/**
 * Encrypt data using AES and return a base64-encoded string.
 *
 * @param {Object} data - The data to be encrypted.
 * @returns {string} - The encrypted data as a base64 string.
 */
export function encryptData(data) {
  try {
    // Encrypt the data
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data), // Stringify the data to ensure it's in text format
      CryptoJS.enc.Utf8.parse(key), // Convert the key to the required format
      {
        iv: CryptoJS.enc.Utf8.parse(encryptionIV), // Convert the IV to the required format
        mode: CryptoJS.mode.CBC, // Set encryption mode to CBC
        padding: CryptoJS.pad.Pkcs7, // Use PKCS7 padding
      }
    );

    // Return the base64-encoded encrypted string
    return encrypted.toString();
  } catch (error) {
    throw new Error("Failed to encrypt data");
  }
}

export const callAPI = async (url, method, requestHeaders, body) => {
  const token = localStorage.getItem("token");
  const sessionId = localStorage.getItem("sessionId");
  const userToken = token;

  requestHeaders["Authorization"] = `Bearer ${userToken}`;
  requestHeaders["sessionId"] = sessionId;

  const fetchData = () =>
    new Promise((resolve, reject) => {
      let encryptBody;
      if (requestHeaders["Content-Type"] === "multipart/form-data") {
        encryptBody = body;
      } else {
        encryptBody =
          body === undefined
            ? undefined
            : JSON.stringify({ data: encryptData(body) });
      }
      requestHeaders["platform"] = "web";

      fetch(url, {
        method: method,
        headers: requestHeaders,
        body: encryptBody,
      })
        .then((response) => {
          let statusCode = +response.status;

          if (statusCode < 300) {
            resolve(response.json());
          } else {
            if (statusCode === 401 || statusCode === 403) {
              localStorage.clear();
              window.location.href = "/login";
            }
            if (statusCode === 444) {
              window.location.href = "/dashboard";
            }
            reject(response.json());
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });

  // Check for internet connectivity
  const checkConnectivity = () => {
    if (!navigator.onLine) {
      showConnectivityPopup(); // Show popup when offline
      return false;
    }
    return true;
  };

  const showConnectivityPopup = () => {
    const existingPopup = document.getElementById("connectivity-popup");
    if (!existingPopup) {
      const popup = document.createElement("div");
      popup.id = "connectivity-popup";
      popup.style.position = "absolute";
      popup.style.top = "20px";
      popup.style.right = "20px";
      popup.style.padding = "10px 20px";
      popup.style.backgroundColor = "#f44336";
      popup.style.color = "#fff";
      popup.style.borderRadius = "5px";
      popup.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
      popup.style.zIndex = 9999;
      popup.innerHTML = "Internet connectivity lost.........";
      document.body.appendChild(popup);
    }
  };

  const hideConnectivityPopup = () => {
    const popup = document.getElementById("connectivity-popup");
    if (popup) {
      popup.remove();
    }
  };

  // Retry the API call until connectivity is restored, then refresh the page
  const retryUntilConnected = () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (checkConnectivity()) {
          clearInterval(interval);
          hideConnectivityPopup(); // Hide popup when back online
          window.location.reload(); // Refresh the page when back online
        }
      }, 3000); // Check connectivity every 3 seconds
    });
  };

  // Check if online first, otherwise retry
  if (!checkConnectivity()) {
    return retryUntilConnected();
  } else {
    return fetchData();
  }
};
