import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Table from "../components/table";
import { getUsersList, handleUserDelete } from "../networks/adminApi";
import moment from "moment/moment";
import { toast } from "react-toastify";
import LinkComponent from "../components/linkComponent";
import Pagination from "../components/pagination";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button as MuiButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { BASE_URL } from "../commonservice/commonservice";
import { callAPI } from "../networks/callApi";
import Loading from "../pages/loader/Loading";
import { setAllUserList } from "../store/reducer/usersReducer";
import { useDispatch, useSelector } from "react-redux";
import { SocketProvider } from "../socket/SocketsContextProvider";

const BlockUnblockModal = ({ isOpen, onClose, onConfirm, user }) => {
  const [action, setAction] = useState("");

  const handleSuspendAction = () => {
    onConfirm(user._id, action);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Suspend/Unsuspend User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select an action for {user?.first_name}:
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="action-label">Action</InputLabel>
          <Select
            labelId="action-label"
            value={action}
            onChange={(e) => setAction(e.target.value)}
            label="Action"
          >
            <MenuItem value="suspend">Suspend</MenuItem>
            <MenuItem value="unsuspend">Unsuspend</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={onClose}>Cancel</MuiButton>
        <MuiButton onClick={handleSuspendAction} color="primary">
          Confirm
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

const ActionButton = ({ user, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirm = async (userId, action) => {
    try {
      const d = await handleUserDelete(userId, action);
      d?.response?.data?.message
        ? toast.error(d.response.data.message)
        : toast.success(d?.data?.message);
      refreshData();
    } catch (error) {
      toast.error("An error occurred while updating the user status.");
    }
  };

  return (
    <div className="flex items-center">
      <button
        onClick={() => setIsModalOpen(true)}
        className="text-blue-500 hover:text-blue-700"
      >
        <FaEdit size={20} style={{ marginLeft: "50px" }} />
      </button>
      <BlockUnblockModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        user={user}
      />
    </div>
  );
};

const UsersList = () => {
  const [usersList, setUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [amount, setAmount] = useState();
  const [searchTerm, setSearchTerm] = useState(""); // Added search term state
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { allUserList } = useSelector((state) => state.usersList);
  const { socketRef } = useContext(SocketProvider);

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    depositAmount: "",
    winningAmount: "",
    bonusAmount: "",
  });

  const handleOpenModal = (value) => {
    setFormData(value);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeModal = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSaveModal = () => {
    if (formData.depositAmount < 0) {
      toast.error("DepositAmount Cannot be negative");
      return;
    }
    if (formData.bonusAmount < 0) {
      toast.error("BonusAmount Cannot be negative");
      return;
    }
    if (formData.winningAmount < 0) {
      toast.error("WinningAmount Cannot be negative");
      return;
    }
    callAPI(
      BASE_URL + ENDPOINTS_API.USEREDITAMOUNT,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        ...formData,
      })
    )
      .then((res) => {
        handleCloseModal();
        toast.success("User Edited Successfully");
        fetchData();
      })
      .catch((err) => {});
  };

  const handleChange = (e) => {
    setStatus(e.target.value);
  };
  const handleClickOpen = (id) => {
    setId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlelist = (id) => {
    navigate(`/redeemedlist/${id}`);
  };

  const handleReferal = (id) => {
    navigate(`/referal/${id}`);
  };

  const fetchData = async () => {
    if (allUserList?.length < 1) {
      setLoading(true);
    }
    const startTime = Date.now();
    try {
      const res = await getUsersList(currentPage, searchTerm);
      const endTime = Date.now();

      // Calculate and log the response time
      const responseTime = endTime - startTime;

      if (res) {
        setUsersList(res?.users);
        setTotalPages(res?.totalPages);
        dispatch(setAllUserList(res?.users));
      }
    } catch (error) {
      toast.error("An error occurred while fetching the users list.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm]);

  const handleupdateamount = () => {
    if (amount < 0) {
      toast.error("Value Cannot be negative");
      return;
    }
    if (!status) {
      toast.error("Please select an action (Increment/Decrement)");
      return;
    }
    callAPI(
      BASE_URL + ENDPOINTS_API.UPDATE_BALANCE,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        amount: Number(amount),
        userId: id,
        action: status,
      })
    )
      .then((res) => {
        toast.success("Amount Updated successfully");

        if (socketRef.current) {
          socketRef.current.emit("update-balance", { id });
        }

        fetchData();
        setAmount("");
        setStatus("");
      })
      .catch((err) => {});
  };

  const filteredUsers = allUserList?.filter((user) => {
    return (
      String(user?.name)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(user?.email)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(user?._id)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(user?.mobile)?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const columns = [
    { Header: "S.No", accessor: "Sno" },
    { Header: "Name", accessor: "name" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "Email", accessor: "email" },
    { Header: "User Id", accessor: "userId" },
    { Header: "Edit Amount", accessor: "editamount" },
    { Header: "Transaction", accessor: "transaction" },
    { Header: "Date of Birth", accessor: "date" },
    { Header: "Redeemed Coupon", accessor: "redeemedcoupon" },
    { Header: "Coupon", accessor: "coupon" },
    { Header: "Total Balance", accessor: "balance" },
    { Header: "ScratchCard Earnings", accessor: "scratchCard" },
    { Header: "Referal Earnings", accessor: "referalearning" },
    { Header: "E-sport Played", accessor: "esport" },
    { Header: "Ludo Played", accessor: "ludoPlayed" },
    { Header: "Winning Amount", accessor: "winningamount" },
    {
      Header: "Created At",
      accessor: "createdAt",
    },
    { Header: "Status", accessor: "status" },
    { Header: "Edit User", accessor: "edituser" },
    { Header: "Action", accessor: "action" },
  ];

  const registrationObj = {
    heading: "New Registration",
    subHeading: "Manage registered users here",
  };

  let registrationData = [];
  filteredUsers &&
    filteredUsers?.forEach((item) => {
      registrationData.push({
        Sno: item.s_no,
        _id: item._id,
        name: item.name ? item.name : "Not Updated",
        email: item.email,
        userId: item._id,
        editamount: (
          <button
            onClick={() => handleClickOpen(item._id)}
            style={{
              width: "8vw",
              background: "#2657cf",
              height: "4.5vh",
              borderRadius: "5px",
            }}
          >
            Edit Amount
          </button>
        ),
        coupon: (
          <div className="w-[100px]">
            <LinkComponent
              path={`/couponList/${item._id}`}
              lable={"Coupon List"}
            />
          </div>
        ),
        transaction: (
          <LinkComponent
            path={`/user-transaction/${item._id}`}
            lable={"Transaction"}
          />
        ),
        mobile: item.mobile ? item.mobile : "Not Updated",
        date: item.dob ? item.dob : "Not Updated",
        redeemedcoupon: (
          <button
            onClick={() => handlelist(item._id)}
            style={{
              border: "1px solid",
              width: "12vw",
              height: "5vh",
              borderRadius: "4px",
            }}
          >
            Redeemed Coupon
          </button>
        ),
        balance: item.bonusAmount + item.depositAmount + item.winningAmount,
        scratchCard: item.totalCouponMoney,
        referalearning: (
          <button
            onClick={() => handleReferal(item._id)}
            style={{
              border: "1px solid",
              width: "10vw",
              height: "5vh",
              borderRadius: "4px",
            }}
          >
            Referal Earning
          </button>
        ),
        esport: item.totalSportsGames,
        ludoPlayed: item.totalLudoGame,
        winningamount: item.winningAmount,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        status: (
          <span
            style={{
              color: item.isBlocked ? "red" : "lightgreen",
              padding: "4px 8px",
              borderRadius: "4px",
              fontWeight: "bold",
            }}
          >
            {item.isBlocked ? "Inactive" : "Active"}
          </span>
        ),
        edituser: (
          <span
            onClick={() => handleOpenModal(item)}
            style={{
              padding: "3px 5px",
              borderRadius: "4px",
              fontWeight: "bold",
              border: "1px solid ",
              cursor: "pointer",
            }}
          >
            {" "}
            Edit User
          </span>
        ),

        action: <ActionButton user={item} refreshData={fetchData} />,
      });
    });

  return (
    <div>
      <div className="search-bar" style={{ marginTop: "1rem" }}>
        <input
          type="text"
          placeholder="Search here....."
          value={searchTerm}
          onChange={handleSearch}
          style={{
            padding: "5px",
            width: "100%",
            maxWidth: "300px",
            borderRadius: "4px",
            border: "1px solid",
            marginLeft: "5px",
          }}
        />
      </div>
      <div className="mx-4 pb-4 pt-4">
        <LinkComponent path={"/blocked_user"} lable={"Suspended Users"} />
        <Table
          columns={columns}
          data={registrationData}
          titleData={registrationObj}
          headingSize={"text-[1rem]"}
        />
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      {loading && <Loading />}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;

            handleClose();
          },
        }}
      >
        <DialogTitle style={{ textAlign: "center" }}>Edit Amount</DialogTitle>
        <DialogContent style={{ width: "30vw" }}>
          <div className="dialog_inputs">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Amount</label>
              <input
                style={{ border: "1px solid" }}
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>

          <TextField
            style={{ width: "26vw", marginTop: "10px" }}
            select
            fullWidth
            label="Select Amount Type"
            value={status}
            onChange={handleChange}
          >
            <MenuItem value="increment">Increment</MenuItem>
            <MenuItem value="decrement">Decrement</MenuItem>
          </TextField>
        </DialogContent>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <button
            onClick={handleupdateamount}
            style={{
              width: "8vw",
              height: "5vh",
              background: "#226fcf",
              color: "white",
              borderRadius: "5px",
            }}
          >
            Submit
          </button>
        </div>
      </Dialog>

      <Dialog open={openModal} data={data} onClose={handleCloseModal}>
        <DialogTitle>Edit User Details</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Username"
            type="text"
            fullWidth
            value={formData?.name}
            onChange={handleChangeModal}
          />
          <TextField
            margin="dense"
            name="mobile"
            label="Number"
            type="text"
            fullWidth
            value={formData?.mobile}
            onChange={handleChangeModal}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={formData?.email}
            onChange={handleChangeModal}
          />

          <TextField
            margin="dense"
            name="depositAmount"
            label="Deposit Amount"
            type="number"
            fullWidth
            value={formData?.depositAmount}
            onChange={handleChangeModal}
          />
          <TextField
            margin="dense"
            name="winningAmount"
            label="Winning Amount"
            type="number"
            fullWidth
            value={formData?.winningAmount}
            onChange={handleChangeModal}
          />
          <TextField
            margin="dense"
            name="bonusAmount"
            label="Bonus Amount"
            type="number"
            fullWidth
            value={formData?.bonusAmount}
            onChange={handleChangeModal}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSaveModal} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersList;
