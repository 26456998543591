/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import { callAPI } from "../../networks/callApi";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { handleUpdateGame } from "../../networks/adminApi";

const EditGameModal = ({ open, handleClose, game }) => {
  const [gameDetails, setGameDetails] = useState({
    title: "",
    name: "",
    image: null, // Add image to the state
    imagePreview: "", // Preview for displaying the image
  });

  // Fetch game details and set state when modal opens
  useEffect(() => {
    setGameDetails({
      title: game?.title || "",
      name: game?.name || "",
      imagePreview: game?.image || "", // Prepopulate the existing image URL for preview
    });
  }, [game]);

  // Handle changes for text inputs
  const handleChange = (e) => {
    setGameDetails({ ...gameDetails, [e.target.name]: e.target.value });
  };

  // Handle image upload and set state
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setGameDetails({ ...gameDetails, image: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setGameDetails((prev) => ({ ...prev, imagePreview: reader.result }));
      };
      reader.readAsDataURL(file); // Preview the uploaded image
    }
  };

  // Handle form submission (with image upload)
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("id", game.id); // Include game ID
    formData.append("title", gameDetails.title);
    formData.append("name", gameDetails.name);
    if (gameDetails.image) {
      formData.append("image", gameDetails.image); // Append the image if it exists
    }

    const res = await handleUpdateGame({ formdata: formData });
    if (res?.success) {
      toast.success("Game Updated successfully");
    }

    // callAPI(
    //   BASE_URL + ENDPOINTS_API.UPDATE_GAME,
    //   "POST",
    //   {},
    //   formData // Send as FormData
    // )
    //   .then((res) => {
    //     toast.success("Game Updated successfully");
    //     handleClose();
    //     window.location.reload();
    //   })
    //   .catch((err) => {
    //     toast.error("Failed to update game");
    //   });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Game</DialogTitle>
      <DialogContent>
        <div>
          <label>Title</label>
          <input
            type="text"
            name="title"
            value={gameDetails.title}
            onChange={handleChange}
            style={{
              width: "100%",
              padding: "8px",
              marginBottom: "8px",
              border: "1px solid black",
            }}
          />

          <label>Game Name</label>
          <input
            type="text"
            name="name"
            value={gameDetails.name}
            onChange={handleChange}
            style={{
              width: "100%",
              padding: "8px",
              marginBottom: "8px",
              border: "1px solid black",
            }}
          />

          <label>Game Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{
              display: "block",
              marginBottom: "8px",
            }}
          />
          {/* Display image preview */}
          {gameDetails.imagePreview && (
            <div style={{ marginBottom: "8px" }}>
              <img
                src={gameDetails.imagePreview}
                alt="Image Preview"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <button
          onClick={handleClose}
          style={{ padding: "8px", backgroundColor: "#ccc" }}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          style={{ padding: "8px", backgroundColor: "#226dcf", color: "white" }}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGameModal;
