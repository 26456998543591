import React, { useEffect, useState } from "react";
import {
  handleCancellChallenge,
  handleGetLudoChallenges,
} from "../../networks/adminApi";
import Table from "../../components/table";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MuiButton,
} from "@mui/material";
import Loading from "../loader/Loading";
import { setAllLudoList } from "../../store/reducer/usersReducer";
import { useDispatch, useSelector } from "react-redux";
const CancelChallenge = ({ challengeId, setChallengeCancell }) => {
  const [open, setOpen] = useState(false);

  const handleCancel = async () => {
    const res = await handleCancellChallenge({ challengeId });

    if (res?.success) {
      toast.success("Challenge Cancelled Successfully");
      setChallengeCancell(true);
    } else {
      toast.error("Deleting Challenge Failed");
    }
    setOpen(false);
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <>
      <button onClick={handleOpenModal}>
        <MdDelete />
      </button>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Cancell this challenge?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton
            variant="contained"
            onClick={handleCancel}
            color="secondary"
          >
            Yes
          </MuiButton>
          <MuiButton
            variant="contained"
            onClick={handleCloseModal}
            color="primary"
          >
            No
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AllLudoChallenges = () => {
  const [ludoChallengs, setLudoChallenges] = useState([]);
  const [challengeCancelled, setChallengeCancell] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [filter, setFilter] = useState();
  const navigate = useNavigate();
  const { allLudoList } = useSelector((state) => state.usersList);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, SetLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (allLudoList?.length < 1) {
        SetLoading(true);
      }

      try {
        const res = await handleGetLudoChallenges(
          currentPage,
          searchTerm,
          filter
        );

        if (res?.data) {
          dispatch(setAllLudoList(res?.data?.challenges));
          setLudoChallenges(res?.data?.challenges);
          setChallengeCancell(false);
          setTotalPages(res.data?.totalPages);
        }
      } finally {
        SetLoading(false);
      }
    };

    fetchData();
  }, [challengeCancelled, currentPage, searchTerm, filter]);

  const filteredchallenges = allLudoList?.filter((item) => {
    const search = searchTerm.toLowerCase();
    return (
      String(item?.prizeMoney)?.toLowerCase().includes(search) ||
      String(item?.appRoomId)?.toLowerCase().includes(search) ||
      String(item?.roomId)?.toLowerCase().includes(search) ||
      String(item?.entryFee)?.toLowerCase().includes(search)
    );
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const columns = [
    { Header: "S.No", accessor: "Sno" },
    { Header: "Game Name", accessor: "roomname" },
    { Header: "Room Id", accessor: "roomId" },
    { Header: "Entry Fee", accessor: "entryFee" },
    { Header: "No Of Player", accessor: "noofplayer" },
    { Header: "No Of Player Joined", accessor: "noofplayerjoined" },
    {
      Header: "Get Detail",
      accessor: "getDetails",
    },
    { Header: "Winning Amount", accessor: "winningamount" },
    { Header: "Delete", accessor: "cancell" },
  ];

  const challengesData = [];
  filteredchallenges &&
    filteredchallenges?.forEach((item) => {
      challengesData.push({
        Sno: item.s_no,
        roomname: item.appRoomId,
        roomId: item.roomId,
        noofplayer: item.noofplayer || 2,
        noofplayerjoined: item.participants?.length,
        entryFee: item.entryFee,
        getDetails: (
          <Button
            onClick={() => navigate(`/getViewDetailsGame/${item._id}`)}
            lable={"getDetail"}
          />
        ),
        winningamount: item.prizeMoney,

        cancell: (
          <CancelChallenge
            challengeId={item._id}
            setChallengeCancell={setChallengeCancell}
          />
        ),
      });
    });

  const tableHeading = {
    heading: "All Ludo Challenges",
  };

  return (
    <>
      {" "}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="search-bar" style={{ marginTop: "1rem" }}>
          <input
            type="text"
            placeholder="Search here...."
            value={searchTerm}
            onChange={handleSearch}
            style={{
              padding: "5px",
              width: "100%",
              maxWidth: "300px",
              borderRadius: "4px",
              border: "1px solid",
              marginLeft: "5px",
            }}
          />
        </div>

        <div style={{ marginRight: "2rem", marginTop: "1rem" }}>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: "10vw", cursor: "pointer", borderRadius: "2px" }}
          >
            <option value="">Select Status</option>
            <option value="upcoming">Pending</option>
            <option value="result">Completed</option>
            <option value="ongoing">Ongoing</option>
            <option value="Error">Error</option>
          </select>
        </div>
      </div>
      <div className="pt-4">
        <Table
          columns={columns}
          data={challengesData}
          titleData={tableHeading}
          headingSize={"text[1rem]"}
        />

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
        {loading && <Loading />}
      </div>
    </>
  );
};

export default AllLudoChallenges;
