import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import { callAPI } from "../../networks/callApi";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";

const EditModal = ({ open, onClose, item, handleGetContactUs }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    setContent(item?.content);
  }, [item]);

  const handleEditContent = () => {
    if (!content) {
      toast.error("Content cannot be empty");
      return;
    }

    callAPI(
      `${BASE_URL + ENDPOINTS_API.ABOUTUS_EDIT}`,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        content: content,
        type: item?.type,
        id: item._id,
      })
    )
      .then((res) => {
        if (res?.success) {
          toast.success("Content updated successfully");
          onClose();
          handleGetContactUs();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <h2>Edit Content</h2>
        <TextField
          fullWidth
          multiline
          rows={6}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Enter new content here"
          variant="outlined"
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleEditContent}
          style={{ marginTop: "1rem" }}
        >
          Update
        </Button>
      </Box>
    </Modal>
  );
};

export default EditModal;
