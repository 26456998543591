import axios from "axios";
import { encryptData } from "../networks/callApi";
// export const BASE_URL = "https://battleopapi.kickrtechnology.in/api/v1/admin/";
// export const BASE_URL_USER = "https://battleopapi.kickrtechnology.in/api/v1/";
export const BASE_URL = "https://api.battleop.in/api/v1/admin/";
export const BASE_URL_USER = "https://api.battleop.in/api/v1/";

export const Instance = async (method, url, header, data) => {
  // const isInternet = await checkInternetConnection();

  const sessionId = localStorage.getItem("sessionId");

  let body = data;
  let encryptBody;
  if (header["Content-Type"] === "multipart/form-data") {
    encryptBody = body;
  } else {
    encryptBody = body === undefined ? undefined : { data: encryptData(body) };
    // header["Content-Type"] = "application/json";
  }

  header["sessionId"] = sessionId;

  header["platform"] = "web";
  try {
    const result = await axios({
      method: method,
      url: url,
      headers: header,
      data: encryptBody,
    });

    if (result.status === 401 || result.status === 404) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      return result;
    }
  } catch (e) {
    return e;
  }
};
