import React from "react";
import { io } from "socket.io-client";

import { createContext, useEffect, useRef } from "react";
import { auth } from "../firebase";

export const SocketProvider = createContext();

const SocketsContextProvider = ({ children }) => {
  const socketRef = useRef(null);

  const base = "https://battleopapi.kickrtechnology.in";

  useEffect(() => {
    socketRef.current = io(base, {
      transports: ["websocket"],
      query: {
        token: "admin",
      },
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <SocketProvider.Provider value={{ socketRef }}>
      {children}
    </SocketProvider.Provider>
  );
};

export default SocketsContextProvider;
