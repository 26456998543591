import { useEffect, useRef, useState } from "react";
import "./style.css";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { callAPI } from "../../../networks/callApi";
import { BASE_URL, BASE_URL_USER } from "../../../commonservice/commonservice";
import { ENDPOINTS_API } from "../../../networks/apiConstants";
import moment from "moment";

export default function TicketReply() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [ticket, setTicket] = useState({});

  const [close, setClose] = useState(false);
  const [error, setError] = useState("");

  const { id } = useParams();
  const state = useLocation();
  const ticketStatus = state.state;

  const handleTicketById = () => {
    callAPI(BASE_URL_USER + ENDPOINTS_API.TICKET_BY_ID + `/${id}`, "GET", {
      "content-type": "application/json",
    })
      .then((res) => {
        setTicket(res.data.ticket);
        setMessages(res.data.messages);
      })
      .catch((err) => {});
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
    setError("");
  };

  const scrollContainerRef = useRef(null);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      const scrollHeight = scrollContainerRef.current.scrollHeight;
      const clientHeight = scrollContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      scrollContainerRef.current.scrollTop = maxScrollTop;
    }
  };

  // Scroll to the bottom on component mount or when content changes
  useEffect(() => {
    scrollToBottom();
  }, [ticket]);

  const handleClose = (e) => {
    setClose(e.target.checked);
  };
  useEffect(() => {
    handleTicketById();
  }, []);

  const handleReply = () => {
    if (message.trim() !== "") {
      callAPI(
        BASE_URL_USER + ENDPOINTS_API.SEND_MESSAGE,
        "POST",
        {
          "content-type": "application/json",
        },
        JSON.stringify({
          status: close ? "closed" : "open",
          ticketId: ticket._id,
          message: message,
          sender: "admin",
        })
      )
        .then((res) => {
          setMessage("");
          handleTicketById();
          if (close) {
            navigate("/support");
          }
        })
        .catch((err) => {
          const newmesage = err.then((res) => {
            toast.error(res?.data);
          });
        });
    } else {
      toast.error("Please enter a message");
    }
  };

  return (
    <div className="ticket_reply_box">
      <div
        className={
          ticket?.status === "Open"
            ? "ticket_reply_Messages"
            : "ticket_reply_all_Messages"
        }
        id="Messages_body"
        ref={scrollContainerRef}
      >
        {messages?.length &&
          messages?.map((item, index) => {
            return (
              <div
                className={
                  item.sender === "admin" ? "out_message" : "in_message"
                }
                key={index}
              >
                {item?.message}
                <p className="ticket_message_date">
                  {moment(item?.createdAt).format("DD-MM-YYYY , LT")}
                </p>
              </div>
            );
          })}
      </div>

      {ticket?.status === "open" ? (
        <div className="reply_box">
          <div className="reply_box_container">
            <textarea
              placeholder="enter your message here..."
              className="reply_textarea"
              onChange={handleMessage}
              value={message}
            />
            <div className="close_ticket_message">
              <input type="checkbox" value={close} onClick={handleClose} />
              <p>Close Ticket</p>
              <p className="ticket_error">{error}</p>
            </div>
          </div>

          <button
            onClick={handleReply}
            style={null}
            className="reply_button"
            type="button"
          >
            Reply
          </button>
        </div>
      ) : null}
    </div>
  );
}
