/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import Table from "../components/table";
import Pagination from "../components/pagination";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { BASE_URL } from "../commonservice/commonservice";
import { callAPI } from "../networks/callApi";
import { useParams } from "react-router-dom";

const redeemedcoupon = () => {
  const [referalList, setReferalList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const { id } = useParams();

  const handleRedeemedList = () => {
    callAPI(BASE_URL + ENDPOINTS_API.REFERAL_EARNING_LIST + `/${id}`, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        setReferalList(res?.data?.referredUserDetails);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleRedeemedList();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Bonus Amount", accessor: "bonusamount" },
    { Header: "Winning Amount", accessor: "winningamount" },
    { Header: "Total Earnedbyuser", accessor: "totalearnedbyuser" },
  ];

  const referalData = [];

  referalList &&
    referalList?.forEach((item) => {
      referalData.push({
        name: item.name,
        email: item.email,
        bonusamount: item.bonusAmount,
        winningamount: item.winningAmount,
        totalearnedbyuser: item.totalEarnedByUser,
      });
    });

  const tableHeading = {
    heading: "Referal Earning List",
  };

  return (
    <div>
      <div className="mx-4 pb-4 pt-4">
        <Table
          columns={columns}
          data={referalData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default redeemedcoupon;
