import React, { useEffect, useState } from "react";
import InputComponent from "../../components/inputComponent";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleAddRules } from "../../networks/adminApi";
import SelectComponet from "../../components/selectComponet";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";

const AddMatchRules = () => {
  const [gameName, setGameName] = useState("");
  const [title, setTitle] = useState("");
  const [addRule, setAddRule] = useState("");
  const [rules, setRules] = useState([]);
  const [gameType, setGameType] = useState("");
  const [gameNameSelect, setGameNameSelect] = useState([]);

  const addruleList = ["ludo", "E-sports"];

  const navigate = useNavigate();

  const handleAddRule = async () => {
    const res = await handleAddRules({
      rules,
      title,
      gameType,
      name: gameName,
    });
    if (res?.data?.success) {
      toast.success("Rules Added Successfully");
      navigate("/matchrules");
    }
    if (res?.response?.data?.message) {
      toast.error(res?.response?.data?.message);
    }
  };

  const handleGetGame = () => {
    callAPI(BASE_URL + ENDPOINTS_API.ALLGAME, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        setGameNameSelect(res.data.games);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (gameType === "E-sports") {
      handleGetGame();
    } else {
      setGameNameSelect([]); // Reset if game type is not E-sports
    }
  }, [gameType]);

  return (
    <div className="mx-4 mt-2 text-white">
      <SelectComponet
        value={gameType}
        onSelect={setGameType}
        label={"Game Type"}
        width={"50%"}
        height={"50px"}
        data={addruleList}
      />

      {gameType === "ludo" && (
        <select
          style={{
            width: "100%",
            marginTop: "15px",
            background: "#222d64",
            border: "1px solid",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
        >
          <option value="">Select name</option>
          <option value="ludo">Ludo</option>
        </select>
      )}

      {gameType === "E-sports" && gameNameSelect?.length > 0 && (
        <select
          style={{
            width: "100%",
            marginTop: "15px",
            background: "#222d64",
            border: "1px solid",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          value={gameName}
          onChange={(e) => setGameName(e.target.value)}
        >
          <option value="">Select name</option>
          {gameNameSelect?.map((item) => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      )}

      <InputComponent
        lable={"Match Title"}
        placeholder={"Please enter match title"}
        value={title}
        onChange={setTitle}
      />
      <InputComponent
        lable={"Add Rule"}
        placeholder={"Please enter rule"}
        value={addRule}
        onChange={setAddRule}
      />
      <div className="mt-4 flex justify-between">
        <Button
          lable={"Add Rule"}
          onClick={() => [setRules([...rules, addRule]), setAddRule("")]}
        />
        <Button lable={"Submit Rules"} onClick={handleAddRule} />
      </div>
      {rules.length > 0 && (
        <>
          <div className="mt-4 text-white">{gameName}</div>
          {rules.map((item, index) => (
            <div key={index} className="text-white">
              {item}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default AddMatchRules;
