import React, { useEffect, useState } from "react";
import { handleGetBlockedUerList } from "../networks/adminApi";
import Table from "../components/table";
import Button from "../components/button";
import { handleUserDelete } from "../networks/adminApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const UnblockComponent = ({ id }) => {
  const navigate = useNavigate();
  const handleUnblock = async () => {
    const res = await handleUserDelete(id);

    if (res?.data?.success) {
      navigate(-1);
      toast.success("User unblocked successfylly");
    } else {
      toast.error("error in unblocking user");
    }
  };

  return <Button lable={"Unblock"} onClick={() => handleUnblock()} />;
};

const UserListBlocked = () => {
  const [blockedUser, setBlockedUser] = useState([]);
  const fetchBlockedUser = async () => {
    const res = await handleGetBlockedUerList();

    if (res?.data) {
      setBlockedUser(res?.data);
    }
  };

  useEffect(() => {
    fetchBlockedUser();
  }, []);

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "Email", accessor: "email" },
    { Header: "Date of Birth", accessor: "date" },
    { Header: "Action", accessor: "action" },
  ];

  const blockedUserData = [];
  blockedUser &&
    blockedUser?.forEach((item) => {
      blockedUserData.push({
        _id: item._id,
        name: item.name,
        email: item.email,
        mobile: item.mobile,
        date: item.dob ? item.dob : "Not added",
        action: <UnblockComponent id={item._id} />,
      });
    });

  const tableHeading = {
    heading: "Blocked user",
  };

  return (
    <div>
      <div className="mx-4 pb-4 pt-4">
        <Table
          columns={columns}
          data={blockedUserData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>
    </div>
  );
};

export default UserListBlocked;
