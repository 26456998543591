import React, { useState } from "react";
import InputComponent from "../components/inputComponent";
import Button from "../components/button";
import { handleAdminUpdatePassword } from "../networks/adminApi";
import { toast } from "react-toastify";
import PasswordInputComponent from "../components/passwordInputComponent";
import { useNavigate } from "react-router-dom";

const UpdatePassword = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();

  const validation = () => {
    let valid = true;
    if (password.trim() === "") {
      valid = false;
      setPasswordError("Please enter current password");
    } else {
      setPasswordError("");
    }

    if (newPassword.trim() === "") {
      valid = false;
      setNewPasswordError("Please enter new password");
    } else {
      setNewPasswordError("");
    }

    if (confirmPassword.trim() === "") {
      valid = false;
      setConfirmPasswordError("Please enter confirm password");
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Confirm password does not match");
      valid = false;
    } else {
      setConfirmPasswordError("");
    }
    return valid;
  };

  const updatePassword = async () => {
    if (validation()) {
      const res = await handleAdminUpdatePassword({
        password,
        newPassword,
        confirmPassword,
      });

      if (res?.data?.message === "Password updated successfully") {
        toast.success("Password updated Successfully");
        localStorage.clear("");
        navigate("/login");
      } else if (res?.data?.message === "Current password is incorrect") {
        toast.error("Current Password is incorrert");
      } else if (res.data?.message === "New passwords do not match") {
        toast.error("confirm password do not match");
      }
    }
  };

  return (
    <div className="mx-4">
      <div className="text-center text-white mt-4 text-[1.2rem] font-bold">
        Update Password
      </div>
      <InputComponent
        lable={"Current Password"}
        placeholder={"Enter your current password"}
        value={password}
        onChange={setPassword}
        width={"w-[80%]"}
      />
      {passwordError && <div className="error">{passwordError}</div>}
      <PasswordInputComponent
        lable={"New Password"}
        placeholder={"Enter your current new password"}
        value={newPassword}
        onChange={setNewPassword}
        width={"w-[80%]"}
      />
      {newPasswordError && <div className="error">{newPasswordError}</div>}
      <PasswordInputComponent
        lable={"Confirm Password"}
        placeholder={"Confirm Password"}
        value={confirmPassword}
        onChange={setConfirmPassword}
        width={"w-[80%]"}
      />
      {confirmPasswordError && (
        <div className="error">{confirmPasswordError}</div>
      )}

      <div className="w-[80%] flex justify-center mt-4">
        <Button lable={"Update Password"} onClick={updatePassword} />
      </div>
    </div>
  );
};

export default UpdatePassword;
