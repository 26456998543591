import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import "./style.css";
import { callAPI } from "../../../networks/callApi";
import { BASE_URL, BASE_URL_USER } from "../../../commonservice/commonservice";
import { ENDPOINTS_API } from "../../../networks/apiConstants";
import { toast } from "react-toastify";
const Index = () => {
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleAddissue = () => {
    callAPI(
      BASE_URL + ENDPOINTS_API.ADD_ISSUE,
      "POST",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({ title })
    )
      .then((res) => {
        toast.success("Issue Added Successfully");
        navigate("/AddTicketIssue");
      })
      .catch((err) => {});
  };
  const handleChange = (e) => {
    setTitle(e.target.value);
    if (errors.title) {
      setErrors({ ...errors, title: "" });
    }
  };

  return (
    <div>
      <div className="input_1">
        <label style={{ color: "white" }}>Add Issue Name</label>
        <input
          type="text"
          placeholder=""
          value={title}
          onChange={handleChange}
          name="title"
        />
        {errors.title && <p className="error">{errors.title}</p>}
      </div>

      <div>
        <button onClick={handleAddissue} type="button" className="sbmt_btnn">
          Submit
        </button>
      </div>
    </div>
  );
};

export default Index;
